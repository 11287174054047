<template>
  <v-app id="ticket_register">
    <v-container class="rounded-lg">
      <v-row class="pt-4">
        <v-col cols="12" sm="10" md="8" offset-sm="1" offset-md="2">
          <h2 class="text-h4 primary--text pb-4 font-weight-black pt-8 pt-md-0 pt-lg-0">Nueva oportunidad</h2>
          <v-card class="px-4 py-6 rounded-lg">
            <TicketForm />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import TicketForm from "@/components/forms/TicketForm";

export default {
  name: "ContactRegister",
  components: {
    TicketForm,
  },
};
</script>
<style>
.v-text-field__details,
.v-messages.theme--light {
  display: block !important;
}
.v-input {
  height: 52px;
}
</style>
