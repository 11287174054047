<template>
  <div id="contact_form">
    <v-col cols="12" class="pa-0">
      <v-form v-if="contact" id="form-contact" ref="form" lazy-validation>
        <VFormBase :value="contact" :schema="schema" />
        <v-col v-if="action === 'create'" cols="12">
          <v-btn color="primary" :loading="loading" @click="create">Guardar contacto</v-btn>
        </v-col>
        <v-col v-else cols="12">
          <v-btn small rounded color="accent" :loading="loading" @click="update">Guardar</v-btn>
          <v-btn small rounded @click="cancelUpdate">Cancelar</v-btn>
        </v-col>
      </v-form>
    </v-col>
  </div>
</template>
<script>
import VFormBase from "vuetify-form-base";
import { mapGetters, mapActions } from "vuex";
import { getCountryList, getCityList } from "@/helpers";
import getModel from "@/helpers/get_model";
import config from "Config/config";

export default {
  name: "ContactForm",
  components: { VFormBase },
  props: {
    action: {
      type: String,
      default: "create",
    },
  },
  data() {
    return {
      contact: null,
      schema: null,
      loading: false,
      countries: null,
    };
  },
  computed: {
    ...mapGetters(["getAgentsToArray", "getContact"]),
  },
  watch: {
    async getContact(value) {
      if (value && this.action === "update") this.contact = Object.assign({}, value);
    },
    async "contact.country"(value) {
      if (value) {
        if (!this.countries) await this.loadCountries();
        this.country_selected = this.countries.find((c) => {
          return c.name == value;
        });
        if (this.country_selected) {
          this.cities = await getCityList(this.country_selected.alpha2code);
          this.schema.city.items = this.cities;
        }
      }
    },
    async "contact.agent"(value) {
      if (value && value.length > 0) {
        let agent_find = this.getAgentsToArray.find((c) => {
          return c.full_name == value;
        });
        if (agent_find) {
          this.agent_selected = {
            email: agent_find.email,
            full_name: agent_find.full_name,
            uid: agent_find.uid,
          };
        }
      }
    },
  },
  async created() {
    this.loadCountries();
    if (this.action === "update") {
      this.schema = this.getUpdateFormSchema(true);
      this.contact = getModel(this.schema, this.getContact);
    } else {
      this.schema = this.getUpdateFormSchema();
      this.contact = getModel(this.schema, {});
      await this.getAllAgents();
      this.loadAgents();
    }
  },
  methods: {
    ...mapActions(["createContact", "getAllAgents", "updateContact"]),
    getUpdateFormSchema(update = false) {
      let schema_update = {};
      let schema_field;
      let form_schema = Object.assign({}, config.contactFormSchema);
      let aux_field;

      for (let field in form_schema) {
        schema_field = null;
        aux_field = Object.assign({}, form_schema[field]);

        if (update && aux_field.form_update) {
          aux_field.flex = { xs: 12 };
          aux_field.rules = [];
          schema_field = aux_field;
        } else if (!update) {
          schema_field = aux_field;
        }

        if (schema_field && schema_field.type != "hidden") schema_update[field] = schema_field;
      }

      return schema_update;
    },
    loadAgents() {
      let agents_sellers = [];
      for (var i = 0; i < this.getAgentsToArray.length; i++) {
        agents_sellers.push(this.getAgentsToArray[i].full_name);
      }
      this.schema.agent.items = agents_sellers;
    },
    async loadCountries() {
      this.countries = await getCountryList();
      let countries_names = [];
      for (var i = 0; i < this.countries.length; i++) {
        countries_names.push(this.countries[i].name);
      }
      this.schema.country.items = countries_names;
    },
    addExtraData() {
      if (this.agent_selected) {
        this.contact.agent_id = this.agent_selected.uid;
        this.contact.agent = this.agent_selected;
      }
      if (this.country_selected) {
        this.contact.country = this.country_selected.name;
        this.contact.country_alpha3code = this.country_selected.alpha3code;
      }
    },
    async create() {
      try {
        this.loading = true;
        this.addExtraData();
        if (this.$refs.form.validate()) {
          let response = await this.createContact({
            contact: this.contact,
          });
          if (response.data) this.$router.replace(`/contact/${response.data.id}`);
        }
      } catch (error) {
        console.error("contact/create: ", error);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;
        this.addExtraData();
        if (this.$refs.form.validate()) {
          const response = await this.updateContact({ contact_id: this.getContact.id, data: this.contact });
          if (response) this.cancelUpdate();
        }
      } catch (error) {
        console.error("contact/update: ", error);
      } finally {
        this.loading = false;
      }
    },
    cancelUpdate() {
      this.$emit("cancelInformation");
    },
  },
};
</script>
<style>
#form-contact .item {
  padding: 0.5rem;
}
</style>
