import * as MutationsType from "./mutationsType";
import { ticketsCollection } from "@/config/firebase";
import requestAxios from "@/helpers/request_axios";
import { roles } from "@/helpers/get_constants";
import getCurrentUser from "@/helpers/get_current_user";

export default {
  // eslint-disable-next-line
  async createTicket({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/ticket",
        method: "POST",
        data: {
          origin: "agent",
          ...payload.ticket,
        },
      });
      return response.data;
    } catch (error) {
      console.error("createTicket: ", error);
      return error.response.data;
    }
  },
  async getTicketById({ commit }, payload) {
    try {
      await ticketsCollection.doc(payload.ticket_id).onSnapshot(async (doc) => {
        let ticket = { id: doc.id, ...doc.data() };
        commit(MutationsType.SET_TICKET, ticket);
        commit(MutationsType.UPDATE_TICKET, { id: doc.id, value: ticket });
      });
    } catch (error) {
      console.error("getTicketById: ", error);
    }
  },
  async setGroupTickets({ commit }, payload) {
    commit(MutationsType.SET_TICKETS, payload.tickets_selected);
  },
  async getAllTickets({ commit }) {
    let agent = await getCurrentUser();
    let reference = ticketsCollection;

    switch (agent.role) {
      case roles.ADVISOR:
        reference = reference.where("agent_id", "==", agent.uid);
        break;
      case roles.SUPERVISOR:
        reference = reference.where("team_id", "==", agent.team_id);
        break;
    }

    await reference.orderBy("created_at", "desc").onSnapshot(async (snap) => {
      let data;
      commit(MutationsType.SET_TICKETS, []);
      snap.docs.forEach((doc) => {
        data = doc.data();
        commit(MutationsType.ADD_TICKET, { id: doc.id, ...data });
      });
    });
  },
  async getAllNewTickets({ commit }, payload) {
    try {
      let agent = await getCurrentUser();
      let reference = ticketsCollection;

      switch (agent.role) {
        case roles.ADVISOR:
          reference = reference.where("agent_id", "==", agent.uid);
          break;
        case roles.SUPERVISOR:
          reference = reference.where("team_id", "==", agent.team_id);
          break;
      }

      reference = reference.orderBy("created_at", "asc");

      if (payload.last_ticket) {
        const start = await ticketsCollection.doc(payload.last_ticket.id).get();
        reference = reference.startAfter(start);
      }

      await reference.onSnapshot(async (snap) => {
        let newTickets = [];

        snap.docs.forEach((doc) => {
          newTickets.push({ id: doc.id, ...doc.data() });
        });

        commit(MutationsType.SET_NEW_TICKETS, newTickets);
      });
    } catch (error) {
      console.error("getAllNewTickets: ", error);
    }
  },
  async getTicketsWithFilters({ commit }, payload) {
    let ref = ticketsCollection;
    let filters = payload.filters;
    let tickets = [];

    if (filters.origin) ref = ref.where("origin", "==", filters.origin);
    if (filters.tags && filters.tags.length > 0) ref = ref.where("tags", "array-contains-any", filters.tags);

    filters.date_start = `${filters.date_start} 00:00:00`;
    filters.date_end = `${filters.date_end} 23:59:00`;

    ref = ref
      .where("created_at", ">=", new Date(filters.date_start))
      .where("created_at", "<=", new Date(filters.date_end));

    let snap = await ref.orderBy("created_at", "desc").limit(10).get();
    let emails = new Set();
    let data;

    snap.forEach((doc) => {
      data = doc.data();

      if (data.email && !emails.has(data.email) && emails.size < 6) {
        emails.add(data.email);
        tickets.push({ id: doc.id, ...data });
      }
    });

    commit(MutationsType.SET_TICKETS, tickets);
    return tickets;
  },
  // eslint-disable-next-line
  async setTicketState({ commit }, payload) {
    try {
      await ticketsCollection.doc(payload.ticket_id).update({ state: payload.state });
      commit(MutationsType.UPDATE_TICKET, { id: payload.ticket_id, add_value: { state: payload.state } });
    } catch (error) {
      console.error("setTicketState: ", error);
    }
  },
  // eslint-disable-next-line
  async setTicketAgent({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/ticket/assign-agent",
        method: "POST",
        data: {
          ticket_id: payload.ticket_id,
          agent_id: payload.agent_id,
        },
      });
      return response.data;
    } catch (error) {
      console.error("setTicketAgent: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async updateTicket({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/ticket/${payload.ticket_id}`,
        method: "PATCH",
        data: payload.data,
      });
      return response.data;
    } catch (error) {
      console.error("updateTicket: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async downloadTickets({ commit }) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/ticket/download",
        method: "GET",
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      console.error("downloadTickets: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async exportTicketTemplate({ commit }) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/ticket/export-template",
        method: "GET",
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      console.error("exportTicketTemplate: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async importTickets({ commit }, payload) {
    try {
      let formdata = new FormData();
      formdata.append("file", payload.file);
      let response = await requestAxios({
        url: "/awy/api/v1/ticket/import",
        method: "POST",
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      console.error("importTickets: ", error);
      throw error.response.data;
    }
  },
  showTicketDetail({ commit }, payload) {
    commit(MutationsType.SHOW_TICKET_DETAIL, `${payload.ticketId}`);
  },
  hideTicketDetail({ commit }) {
    commit(MutationsType.HIDE_TICKET_DETAIL);
  },
};
