<template>
  <div id="agent_form">
    <v-col v-if="agent" cols="12" class="pa-0">
      <v-form id="form-agent" ref="form" lazy-validation>
        <v-row>
          <v-col cols="12" :sm="sm || 6">
            <v-text-field
              v-model="agent.name"
              label="Nombres"
              placeholder=" "
              :rules="rules.required"
              :readonly="isReadOnly"
            ></v-text-field>
          </v-col>
          <v-col cols="12" :sm="sm || 6">
            <v-text-field
              v-model="agent.last_name"
              label="Apellidos"
              placeholder=" "
              :rules="rules.required"
              :readonly="isReadOnly"
            ></v-text-field>
          </v-col>
          <v-col cols="12" :sm="sm || 6">
            <v-text-field
              v-model="agent.email"
              label="Correo electrónico"
              placeholder=" "
              :rules="rules.required"
              :readonly="isReadOnly"
            ></v-text-field>
          </v-col>
          <v-col cols="12" :sm="sm || 6">
            <v-select
              v-model="agent.role"
              placeholder="Seleccione"
              :items="getAgentRolesToArray"
              :rules="rules.required"
              :readonly="isReadOnly"
              item-text="name"
              item-value="id"
              label="Rol"
            ></v-select>
          </v-col>
          <v-col v-if="[$roles.ADVISOR, $roles.SUPERVISOR].includes(agent.role)" cols="12">
            <v-select
              v-model="agent.team_id"
              :items="getTeams"
              item-value="id"
              item-text="name"
              :menu-props="{ maxHeight: '400' }"
              :readonly="isReadOnly"
              :rules="rules.required"
              label="Equipo"
              placeholder=" "
              hint="Para crear un nuevo equipo ve a configuraciones"
              persistent-hint
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="agent.projects"
              :items="getProjects"
              item-value="id"
              item-text="id"
              :menu-props="{ maxHeight: '400' }"
              :readonly="isReadOnly"
              label="Proyectos"
              placeholder=" "
              multiple
              hint="Proyectos donde va a ser asignado automaticamente"
              persistent-hint
            ></v-select>
          </v-col>
          <v-col v-if="errorForm" cols="12">
            <v-alert text type="error">
              {{ errorForm }}
            </v-alert>
          </v-col>
          <v-col v-if="action === 'create'" cols="12">
            <v-btn color="primary" :loading="loading" @click="handleForm">Guardar trabajador</v-btn>
          </v-col>
          <v-col v-if="action === 'update'" cols="12" class="text-center">
            <v-btn small rounded color="accent" :loading="loading" @click="handleForm">Guardar</v-btn>
            <v-btn
              small
              rounded
              @click="
                setAgent();
                $emit('update:action', 'read');
              "
              >Cancelar</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AgentForm",
  props: {
    agentSelect: {
      type: Object,
      default: function () {
        return {};
      },
    },
    action: {
      type: String,
      default: "read",
    },
    sm: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      agent: null,
      loading: false,
      errorForm: null,
      onEdit: false,
      rules: { required: [(v) => !!v || "Campo requerido"] },
    };
  },
  computed: {
    ...mapGetters(["getAgentRolesToArray", "getAgentSelected", "getProjects", "getTeams"]),
    isReadOnly() {
      return this.action === "read";
    },
  },
  watch: {
    agentSelect(value) {
      this.agent = value;
    },
    "agent.role"(value) {
      if ([this.$roles.SUPER_ADMIN, this.$roles.ADMIN].includes(value)) this.agent.team_id = "";
    },
  },
  mounted() {
    this.setAgent();
  },
  methods: {
    ...mapActions(["createAgent", "updateAgent", "recoveryPassword"]),
    async handleForm() {
      try {
        this.loading = true;
        this.errorForm = null;

        if (this.$refs.form.validate()) {
          if (this.action === "create") {
            await this.createAgent(this.agent);
            await this.recoveryPassword({ email: this.agent.email });
            this.$router.replace("/agents");
          } else {
            await this.updateAgent(this.agent);
            this.$emit("update:action", "read");
          }
        }
      } catch (error) {
        this.errorForm = error.message || "Ocurrió un error al actualizar al trabajador";
      } finally {
        this.loading = false;
      }
    },
    setAgent() {
      this.agent = Object.assign({}, this.agentSelect);
    },
  },
};
</script>
