export default {
  getTicketStates(state) {
    return state.ticketStates;
  },
  getActiveTicketStates(state) {
    let states = [];
    if (state.ticketStates) {
      states = [];
      for (let t in state.ticketStates) {
        if (state.ticketStates[t].active) states.push(state.ticketStates[t]);
      }
    }
    return states;
  },
};
