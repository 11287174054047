import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_EVENTS](state, payload) {
    state.events = payload;
  },
  [MutationsType.ADD_EVENT](state, payload) {
    state.events.push(payload);
  },
};
