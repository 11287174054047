<template>
  <v-navigation-drawer v-model="localDrawer" temporary right app width="400">
    <v-card class="pt-4 elevation-0">
      <div class="d-flex justify-end hidden-md-and-up">
        <v-btn class="ma-1" x-small outlined fixed fab right @click.stop.prevent="localDrawer = false">
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </div>
    </v-card>
    <template v-if="getTicket">
      <v-toolbar flat>
        <v-list-item two-line class="pa-0">
          <v-list-item-avatar size="48" color="accent" class="pa-2">
            <span class="white--text subhead"> {{ avatarText }}</span>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="`${getTicket.full_name}`"></v-list-item-title>
            <v-list-item-subtitle v-text="getTicket.email"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <template v-slot:extension>
          <v-tabs v-model="tab" slider-color="primary">
            <v-tab key="1" href="#tab-1">información</v-tab>
            <v-tab key="2" href="#tab-2">Actividad</v-tab>
            <v-tab key="3" href="#tab-3">Relacionado</v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item key="1" value="tab-1">
          <div v-if="!editInformation" class="text-right px-4">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" fab x-small dark v-on="on">
                  <v-icon size="16" @click.stop.prevent="editInformation = true">fas fa-pencil-alt</v-icon>
                </v-btn>
                <!-- <v-btn size="16" fab icon small class="pa-4" color="primary">
                </v-btn> -->
              </template>
              <span>Editar información</span>
            </v-tooltip>
          </div>
          <v-form ref="formInformation" lazy-validation>
            <div class="fill-height text-center px-3 pt-2">
              <v-col v-if="editInformation" cols="12">
                <h4>Editar información de la Oportunidad</h4>
              </v-col>
              <v-col v-if="editInformation" cols="12">
                <TicketForm action="update" @cancelInformation="cancelInformation" />
              </v-col>
              <v-card v-else class="mb-4">
                <template v-for="(ticketField, i) in ticketFields">
                  <v-list :key="i" class="text-left">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-regular">
                          {{ getTicket[ticketField.field] }}
                          <BtnWhatsapp
                            v-if="ticketField.field === 'phone' && getTicket.phone"
                            :number="getTicket.phone"
                          />
                          <BtnGmail v-if="ticketField.field === 'email' && getTicket.email" :email="getTicket.email" />
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ ticketField.label }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mx-2"></v-divider>
                  </v-list>
                </template>
              </v-card>
            </div>
          </v-form>
        </v-tab-item>
        <v-tab-item key="2" value="tab-2">
          <v-card class="pa-2 py-4 ma-2">
            <CommentForm :ticket-id="getSelectedTicketId" :col-fixed="12" />
          </v-card>
          <v-card class="pa-2 py-4 ma-2">
            <Tags />
            <v-divider class="mt-4"></v-divider>
            <Timeline :ticket-id="getSelectedTicketId" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="3" value="tab-3">
          <v-card class="ma-2 pa-2">
            <p class="font-weight-black">ASISTENCIA</p>
            <v-col cols="12" class="d-flex">
              <div v-if="getTicket.agent_id && !loadSaveAgent">
                <v-avatar size="48px" color="black">
                  <img
                    v-if="getAllAgentsMap[getTicket.agent_id].photo"
                    :src="getAllAgentsMap[getTicket.agent_id].photo"
                    alt="Avatar"
                  />
                  <span v-else class="white--text headline">
                    {{ getAllAgentsMap[getTicket.agent_id].full_name.substring(0, 1) }}
                  </span>
                </v-avatar>
              </div>
              <div v-else>
                <v-avatar size="50px" color="black">
                  <span class="white--text headline">-</span>
                </v-avatar>
              </div>
              <v-text-field
                v-if="getAgent.role === $roles.ADVISOR"
                v-model="getAgent.full_name"
                label="Encargado"
                class="pl-2"
                readonly
              >
              </v-text-field>
              <v-select
                v-else
                v-model="agentTicket"
                :items="getAgentsToArray"
                :disabled="loadSaveAgent"
                item-text="full_name"
                item-value="uid"
                :value="getTicket.agent_id"
                label="Encargado"
                placeholder="Sin asignar"
                class="pl-2 w95"
              ></v-select>
            </v-col>
          </v-card>
          <v-card class="ma-2 pa-2">
            <div class="d-flex justify-space-between">
              <p class="font-weight-black">ESTADO</p>

              <div v-if="!editState" class="text-right">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small class="mr-4" v-on="on">
                      <v-icon size="16" @click.stop.prevent="editState = true">fas fa-pencil-alt</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar estado</span>
                </v-tooltip>
              </div>
            </div>
            <v-form ref="formState" lazy-validation>
              <v-row class="fill-height text-center px-3 pt-2">
                <v-col v-if="editState" cols="12" class="pa-0">
                  <!-- <h4>Editar estado de la Oportunidad</h4> -->
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="state"
                    :value="getTicket.state"
                    :items="getActiveTicketStates"
                    :readonly="!editState"
                    style="font-size: 15px;"
                    item-text="name"
                    item-value="id"
                    label="Estado"
                    class="pt-2"
                  ></v-select>
                </v-col>
                <v-col v-if="editState" cols="12">
                  <v-btn small rounded color="primary" @click="saveState">Guardar</v-btn>
                  <v-btn small rounded @click="cancelState">Cancelar</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
          <v-card class="ma-2 pa-2">
            <p class="font-weight-black">FUENTE</p>

            <v-col class="size" cols="12">
              <div>
                Obtenido desde <strong>{{ getTicket.origin }}</strong>
              </div>
            </v-col>
            <v-col v-if="getTicket.created_uid" class="d-flex" cols="12">
              <div>
                <v-avatar size="50px" color="black">
                  <img
                    v-if="getAllAgentsMap[getTicket.created_uid].photo"
                    :src="getAllAgentsMap[getTicket.created_uid].photo"
                    alt="Avatar"
                  />
                  <span v-else class="white--text headline">
                    {{ getAllAgentsMap[getTicket.created_uid].full_name.substring(0, 1) }}
                  </span>
                </v-avatar>
              </div>
              <v-text-field
                :value="getAllAgentsMap[getTicket.created_uid].full_name"
                label="Registrado por:"
                class="pl-2 w95"
                readonly
              ></v-text-field>
            </v-col>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "Config/config";

import TicketForm from "@/components/forms/TicketForm";
import Timeline from "@/components/tickets/Timeline";
import CommentForm from "@/components/tickets/CommentForm";
import Tags from "./options/Tags";
import BtnWhatsapp from "@/components/buttons/BtnWhatsapp.vue";
import BtnGmail from "@/components/buttons/BtnGmail.vue";

const { auth } = require("@/config/firebase");
export default {
  name: "TicketDetailDrawer",
  components: {
    TicketForm,
    Tags,
    Timeline,
    CommentForm,
    BtnWhatsapp,
    BtnGmail,
  },
  data() {
    return {
      tab: "tab-2",
      rules: {
        required: [(v) => !!v || "Campo requerido"],
      },
      agent: null,
      ticket: {
        id: this.getSelectedTicketId,
      },
      tabs: [0, 1, 2],
      ticketFields: [],
      contactFields: [],
      editState: false,
      editInformation: false,
      editContact: false,
      loadSaveInformation: false,
      loadSaveAgent: false,
      state: 0,
    };
  },
  computed: {
    ...mapGetters([
      "getSelectedTicketId",
      "getShowTicketDetailDrawer",
      "getAgent",
      "getAllAgentsMap",
      "getAgentsToArray",
      "getTicket",
      "getActiveTicketStates",
      "getTimeline",
      "getContact",
    ]),
    localDrawer: {
      get() {
        // Carga la data cuando el drawer se abre.
        if (this.getShowTicketDetailDrawer) {
          this.mountedNow();
        }
        return this.getShowTicketDetailDrawer;
      },
      set(v) {
        if (!v) {
          this.ticketFields = [];
          this.contactFields = [];
          this.editState = false;
          this.editInformation = false;
          this.editContact = false;
          this.loadSaveInformation = false;
          this.loadSaveTags = false;
          this.loadSaveAgent = false;
          this.tags = [];
          this.state = 0;
          // this.showTicketDetail({ ticketId: null });
          this.hideTicketDetail();
        }
      },
    },
    agentTicket: {
      get() {
        return this.getTicket != null ? this.getTicket.agent_id : 0;
      },
      set(value) {
        if (this.getTicket.agent_id !== value) this.saveAgent(value);
      },
    },
    entity() {
      return this.getContact || this.getTicket;
    },
    avatarText() {
      return `${this.getTicket.name.charAt(0)}${
        this.getTicket.last_name ? this.getTicket.last_name.charAt(0) : ""
      }`.toUpperCase();
    },
  },
  watch: {
    async getTicket(value) {
      this.state = value.state;
      if (value.contact_id) await this.getContactById({ contact_id: value.contact_id });
    },
  },
  methods: {
    ...mapActions([
      "showTicketDetail",
      "getTicketById",
      "getContactById",
      "setAgent",
      "setTicketState",
      "findAgent",
      "setTicketAgent",
      "getAllAgents",
      "hideTicketDetail",
    ]),
    async mountedNow() {
      this.agent = await auth.currentUser;
      await this.getTicketById({ ticket_id: this.getSelectedTicketId });
      await this.findAgent(this.agent.uid);
      this.getTicketFields();
      this.getContactFields();
      this.agent = { name: this.getAgent.name, full_name: this.getAgent.full_name, photo: this.getAgent.photo };
    },
    getTicketFields() {
      let schema = config.ticketFormSchema;
      for (let field in schema) {
        if (schema[field].show_on_detail) {
          this.ticketFields.push({ field: field, ...schema[field] });
        }
      }
    },
    getContactFields() {
      let schema = config.contactFormSchema;
      for (let field in schema) {
        if (schema[field].show_on_detail) {
          this.contactFields.push({ field: field, ...schema[field] });
        }
      }
    },

    formatCurrency(number, symbol = "S/.") {
      if (isNaN(number)) number = 0;
      return `${symbol} ${parseFloat(number)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
    },
    saveState() {
      if (this.$refs.formState.validate()) {
        this.editState = false;
        this.setTicketState({
          ticket_id: this.getTicket.id,
          state: this.state,
        });
      }
    },
    cancelState() {
      this.editState = false;
      this.state = this.getTicket.state;
    },
    cancelInformation() {
      this.editInformation = false;
    },
    cancelContact() {
      this.editContact = false;
    },

    async saveAgent(agent_id) {
      try {
        this.loadSaveAgent = true;

        await this.setTicketAgent({ ticket_id: this.getTicket.id, agent_id: agent_id });
      } catch (error) {
        console.error("ticket/saveAgent: ", error);
        this.agentTicket = this.getTicket.agent_id;
      } finally {
        this.loadSaveAgent = false;
      }
    },
  },
};
</script>
<style>
.v-slide-group.v-item-group > .v-slide-group__next,
.v-slide-group.v-item-group > .v-slide-group__prev {
  display: none !important;
}
</style>
