<template>
  <div class="pt-16 pt-md-0 pt-lg-0">
    <span class="caption">Última actualización: {{ $moment(date_updated).fromNow() }}</span>
    <iframe :src="report_url" frameborder="0" style="border: 0;" allowfullscreen></iframe>
  </div>
</template>
<script>
import { statitics_url } from "Config/config";
export default {
  name: "Statistics",
  data() {
    return {
      report_url: statitics_url,
      date_updated: "",
    };
  },
  created() {
    const date = new Date();
    date.setHours(date.getHours() >= 6 && date.getHours() < 18 ? 6 : 18);
    date.setMinutes(0);
    this.date_updated = date;
  },
};
</script>
<style scoped>
iframe {
  width: 100%;
  height: 84vh;
}
</style>
