import requestAxios from "@/helpers/request_axios";
import * as MutationsType from "./mutationsType";
import { ticketsStatesCollection, serverTimestamp } from "@/config/firebase";

export default {
  async getAllTicketStates({ commit }) {
    let states = await ticketsStatesCollection.orderBy("created_at").get();
    commit(MutationsType.SET_TICKET_STATES, []);
    if (!states.empty) {
      states.forEach((state) => {
        commit(MutationsType.ADD_TICKET_STATE, { id: state.id, ...state.data() });
      });
    }
  },
  async createTicketState({ commit }, payload) {
    let state = Object.assign({}, payload.state);
    state.created_at = serverTimestamp;
    await ticketsStatesCollection.doc(state.id).set(state);
    commit(MutationsType.ADD_TICKET_STATE, state);
    return state;
  },
  async updateTicketState({ commit }, payload) {
    await ticketsStatesCollection.doc(payload.state.id).update(payload.state);
    commit(MutationsType.UPDATE_TICKET_STATE, payload.state);
    return true;
  },
  async removeTicketState({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/ticket/refactor-state",
        method: "POST",
        data: payload,
      });
      commit(MutationsType.REMOVE_TICKET_STATE, payload.state_to_delete);
      return response.data;
    } catch (error) {
      console.error("removeTicketState: ", error.response.data);
      throw error.response.data;
    }
  },
};
