import { render, staticRenderFns } from "./Detail.vue?vue&type=template&id=8d25be02&"
import script from "./Detail.vue?vue&type=script&lang=js&"
export * from "./Detail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/angyqr/Documents/una/always-with-you/hosting/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8d25be02')) {
      api.createRecord('8d25be02', component.options)
    } else {
      api.reload('8d25be02', component.options)
    }
    module.hot.accept("./Detail.vue?vue&type=template&id=8d25be02&", function () {
      api.rerender('8d25be02', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sales/options/Detail.vue"
export default component.exports