<template>
  <v-list-item key="notifications" @click="assign_tickets_m">
    <v-list-item-avatar>
      <v-icon :class="assign_tickets ? 'far fa-bell' : 'far fa-bell-slash'" text="Notificationes"></v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>Notificationes</v-list-item-title>
      <v-list-item-subtitle
        >Permitir notificaciones cada vez que se me asigne un nueva oportunidad</v-list-item-subtitle
      >
    </v-list-item-content>
    <v-list-item-action>
      <v-switch
        v-model="assign_tickets"
        :loading="load['assign_tickets']"
        :disabled="!allow_receive_notification || load['assign_tickets']"
        @click="assign_tickets_m"
      ></v-switch>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import { messaging } from "@/config/firebase";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Notifications",
  data() {
    return {
      token: null,
      allow_receive_notification: false,
      assign_tickets: false,
      suscribed_assign_tickets: null,
      load: {
        assign_tickets: false,
      },
    };
  },
  computed: {
    ...mapGetters(["getAgent", "getDevices"]),
  },
  watch: {
    getAgent(value) {
      if (value && value.uid) this.getDevicesByAgentId({ agent_id: value.uid });
    },
    getDevices(value) {
      this.assign_tickets = Boolean(value["assign_tickets"]);
      this.suscribed_assign_tickets = Boolean(value["assign_tickets"]);
    },
    assign_tickets(value) {
      if (typeof this.suscribed_assign_tickets === "boolean" && this.suscribed_assign_tickets !== value) {
        this.addNotificationToken("assign_tickets", value);
      }
    },
  },
  mounted() {
    if (this.getAgent && this.getAgent.uid) this.getDevicesByAgentId({ agent_id: this.getAgent.uid });
  },
  created() {
    this.verifyAllowNotifications();
  },
  methods: {
    ...mapActions(["getDevicesByAgentId", "updateNotificationToken"]),
    assign_tickets_m(value) {
      if (typeof this.suscribed_assign_tickets === "boolean" && this.suscribed_assign_tickets !== value) {
        this.addNotificationToken("assign_tickets", value);
      }
    },
    async verifyAllowNotifications() {
      if (!("Notification" in window)) alert("Este navegador no es compatible con las notificaciones de escritorio");
      else this.allow_receive_notification = Notification.permission === "granted" && messaging;
    },
    async addNotificationToken(type, allow) {
      try {
        this.load[type] = true;

        if (!this.allow_receive_notification) return;

        const token = await messaging.getToken();
        const response = await this.updateNotificationToken({
          notification_type: [type],
          allow: allow,
          token: token,
        });

        if (response.data) {
          switch (type) {
            case "assign_tickets":
              this.suscribed_assign_tickets = response.data.allow;
              break;
          }
        }
      } catch (error) {
        console.error("notifications/addNotificationToken: ", error);
        switch (type) {
          case "assign_tickets":
            this.assign_tickets = this.suscribed_assign_tickets;
            break;
        }
      } finally {
        this.load[type] = false;
      }
    },
  },
};
</script>
