import * as MutationsType from "./mutationsType";
import moment from "moment";

const { eventsCollection } = require("@/config/firebase");

export default {
  async getAllEvents({ commit }) {
    let events = await eventsCollection.get();
    commit(MutationsType.SET_EVENTS, []);

    if (!events.empty) {
      let allEvents = [];
      let e;

      events.forEach((event) => {
        e = { id: event.id, ...event.data() };
        e.end = e.end ?? moment(e.start).add(1, "h").toDate();
        e.timed = true;
        allEvents.push(e);
      });

      commit(MutationsType.SET_EVENTS, allEvents);
    }
  },
};
