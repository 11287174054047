import * as MutationsType from "./mutationsType";
/* eslint-disable no-param-reassign */
export default {
  [MutationsType.SET_TAGS](state, payload) {
    state.tags = payload;
  },
  [MutationsType.ADD_TAG](state, payload) {
    state.tags.unshift(payload);
  },
  [MutationsType.UPDATE_TAG](state, payload) {
    let ind = state.tags.findIndex((t) => {
      return t.id == payload.id;
    });
    state.tags[ind].name = payload.name;
    state.tags[ind].color = payload.color;
  },
  [MutationsType.REMOVE_TAG](state, payload) {
    state.tags = state.tags.filter((item) => item.id !== payload.id);
  },
};
