/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import Menu from "@/views/menu/Menu";
import Login from "@/views/auth/Login";
import Sales from "@/views/sales/Sales";
import SaleDetail from "@/views/sales/options/Detail";
import ImporterSales from "@/views/sales/options/Importer";
import Contacts from "@/views/contacts/Contacts";
import ImporterContacts from "@/views/contacts/options/Importer";
import ImporterContactActivities from "@/views/contacts/options/ImporterActivities";
import ContactDetail from "@/views/contacts/options/Detail";
import ContactRegister from "@/views/contacts/options/Register";
import Tickets from "@/views/tickets/Tickets";
import TicketDetail from "@/views/tickets/options/Detail";
import TicketRegister from "@/views/tickets/options/Register";
import TicketDashboard from "@/views/tickets/options/Dashboard";
import ImporterTickets from "@/views/tickets/options/Importer";
import ImporterTicketActivities from "@/views/tickets/options/ImporterActivities";
import ReporterActivities from "@/views/tickets/options/ReportActivities";
import Agents from "@/views/agents/Agents";
import AgentDetail from "@/views/agents/options/Detail";
import AgentRegister from "@/views/agents/options/Register";
import Events from "@/views/events/Events";
import Statistics from "@/views/statistics/Statistics";
import Settings from "@/views/settings/Settings";

import { contactsCollection, ticketsCollection } from "@/config/firebase";

import getCurrentUser from "@/helpers/get_current_user";
import { roles } from "@/helpers/get_constants";

Vue.use(VueRouter);

const allRoles = [roles.ADVISOR, roles.SUPERVISOR, roles.ADMIN, roles.SUPER_ADMIN];

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/opportunities",
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        authorizedRoles: ["anonymous"],
      },
    },
    {
      path: "/contacts",
      name: "contacts",
      component: Contacts,
      props: (route) => ({ state: route.query.state, country: route.query.country, agent: route.query.agent }),
      meta: {
        title: "Contactos",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/contacts/importer",
      name: "importer_contacts",
      component: ImporterContacts,
      meta: {
        title: "Contactos",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/contacts/importer-activities",
      name: "importer_contact_activities",
      component: ImporterContactActivities,
      meta: {
        title: "Contactos",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/contact/register",
      name: "contact_register",
      component: ContactRegister,
      meta: {
        title: "Contactos",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/contact/:id",
      name: "contact_detail",
      component: ContactDetail,
      meta: {
        title: "Contactos",
        authorizedRoles: allRoles,
      },
      async beforeEnter(to, from, next) {
        let response = await contactsCollection.doc(to.params.id).get();
        if (response.exists) {
          if (
            [roles.SUPER_ADMIN, roles.ADMIN].includes(to.meta.user.role) ||
            (to.meta.user.role === roles.SUPERVISOR && response.data().team_id === to.meta.user.team_id) ||
            to.meta.user.uid === response.data().agent_id
          ) {
            next();
          } else {
            next("/contacts");
          }
        } else {
          next("/contacts");
        }
      },
    },
    {
      path: "/sales",
      name: "sales",
      component: Sales,
      meta: {
        title: "Ventas",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/sales/importer",
      name: "importer_sales",
      component: ImporterSales,
      meta: {
        title: "Ventas",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/sale/:id",
      name: "sale_detail",
      component: SaleDetail,
      meta: {
        title: "Ventas",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/opportunities",
      name: "tickets",
      component: Tickets,
      meta: {
        title: "Oportunidades",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/opportunities/dashboard",
      name: "ticket_dashboard",
      component: TicketDashboard,
      meta: {
        title: "Dashboard Oportunidades",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/opportunity/register",
      name: "ticket_register",
      component: TicketRegister,
      meta: {
        title: "Oportunidad",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/opportunity/:id",
      name: "ticket_detail",
      component: TicketDetail,
      meta: {
        title: "Oportunidades",
        authorizedRoles: allRoles,
      },
      async beforeEnter(to, from, next) {
        let response = await ticketsCollection.doc(to.params.id).get();
        if (response.exists) {
          if (
            [roles.SUPER_ADMIN, roles.ADMIN].includes(to.meta.user.role) ||
            (to.meta.user.role === roles.SUPERVISOR && response.data().team_id === to.meta.user.team_id) ||
            to.meta.user.uid === response.data().agent_id
          ) {
            next();
          } else {
            next("/opportunities");
          }
        } else {
          next("/opportunities");
        }
      },
    },
    {
      path: "/opportunities/importer",
      name: "importer_tickets",
      component: ImporterTickets,
      meta: {
        title: "Tickets",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/opportunities/importer-activities",
      name: "importer_ticket_activities",
      component: ImporterTicketActivities,
      meta: {
        title: "Tickets",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/opportunities/report-activities",
      name: "report_activities",
      component: ReporterActivities,
      meta: {
        title: "Tickets",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/agents",
      name: "agents",
      component: Agents,
      meta: {
        title: "Trabajadores",
        authorizedRoles: [roles.ADMIN, roles.SUPER_ADMIN],
      },
    },
    {
      path: "/agents/register",
      name: "agent_register",
      component: AgentRegister,
      meta: {
        title: "Trabajadores",
        authorizedRoles: [roles.SUPER_ADMIN],
      },
    },
    {
      path: "/agent/:id",
      name: "agent_detail",
      component: AgentDetail,
      meta: {
        title: "Trabajadores",
        authorizedRoles: [roles.ADMIN, roles.SUPER_ADMIN],
      },
    },
    {
      path: "/events",
      name: "events",
      component: Events,
      meta: {
        title: "Eventos",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/statistics",
      name: "statistics",
      component: Statistics,
      meta: {
        title: "Estadísticas",
        authorizedRoles: [roles.ADMIN, roles.SUPER_ADMIN],
      },
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
      meta: {
        title: "Configuraciones",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/menu",
      name: "menu",
      component: Menu,
      meta: {
        title: "Menu",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "*",
      redirect: "/login",
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  let authorizedRoles = to.meta.authorizedRoles || [];
  let user = await getCurrentUser();

  if (!authorizedRoles.includes(user.role)) {
    if (user.role === "anonymous") {
      next("/login");
    } else {
      next("/");
    }
  } else {
    to.meta.user = user;
    next();
  }
});

export default router;
