var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "pa-2", attrs: { id: "ticket_detail" } },
    [
      _vm.getAgent &&
      _vm.getTicket &&
      _vm.getActiveTicketStates &&
      _vm.getAgentsToArray
        ? _c(
            "v-row",
            { staticClass: "pt-8 pt-md-0 pt-lg-0" },
            [
              _c(
                "v-col",
                { staticClass: "pa-1", attrs: { cols: "12" } },
                [
                  _c(
                    "v-expansion-panels",
                    {
                      attrs: { outlined: "" },
                      model: {
                        value: _vm.tabs,
                        callback: function($$v) {
                          _vm.tabs = $$v
                        },
                        expression: "tabs"
                      }
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            { staticClass: "text--darken-2 font-weight-bold" },
                            [
                              _vm._v("\n            INFORMACIÓN\n            "),
                              !_vm.editInformation
                                ? _c(
                                    "div",
                                    { staticClass: "text-right" },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          staticClass: "mr-4",
                                                          attrs: {
                                                            size: "16",
                                                            icon: "",
                                                            small: ""
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              size: "16"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                $event.preventDefault()
                                                                _vm.editInformation = true
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fas fa-pencil-alt"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            1825277696
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v("Editar información")
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "formInformation",
                                  attrs: { "lazy-validation": "" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "fill-height text-center px-3"
                                    },
                                    [
                                      _vm.editInformation
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("h4", [
                                                _vm._v(
                                                  "Editar información de la Oportunidad"
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.editInformation
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("TicketForm", {
                                                attrs: { action: "update" },
                                                on: {
                                                  cancelInformation:
                                                    _vm.cancelInformation
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "fill-height text-left"
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pt-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "caption"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "ID OPORTUNIDAD:"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          attrs: {
                                                            color: "accent",
                                                            ripple: false,
                                                            small: ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.ticket.id
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.ticketFields,
                                                    function(ticketField, i) {
                                                      return [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            key: i,
                                                            staticClass:
                                                              "pa-1 px-4 d-flex",
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3"
                                                            }
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                dense: "",
                                                                value:
                                                                  _vm.getTicket[
                                                                    ticketField
                                                                      .field
                                                                  ],
                                                                label:
                                                                  ticketField.label,
                                                                readonly: !_vm.editInformation
                                                              }
                                                            }),
                                                            _vm._v(" "),
                                                            ticketField.field ===
                                                              "phone" &&
                                                            _vm.getTicket.phone
                                                              ? _c(
                                                                  "BtnWhatsapp",
                                                                  {
                                                                    attrs: {
                                                                      number:
                                                                        _vm
                                                                          .getTicket
                                                                          .phone
                                                                    }
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            ticketField.field ===
                                                              "email" &&
                                                            _vm.getTicket.email
                                                              ? _c("BtnGmail", {
                                                                  attrs: {
                                                                    email:
                                                                      _vm
                                                                        .getTicket
                                                                        .email
                                                                  }
                                                                })
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pa-1", attrs: { cols: "12", md: "5" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-expansion-panels",
                            {
                              attrs: { multiple: "", dense: "" },
                              model: {
                                value: _vm.tabs,
                                callback: function($$v) {
                                  _vm.tabs = $$v
                                },
                                expression: "tabs"
                              }
                            },
                            [
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticClass:
                                        "text--darken-2 font-weight-bold"
                                    },
                                    [
                                      _c("span", [
                                        _vm.editState
                                          ? _c("span", [_vm._v("EDITAR")])
                                          : _vm._e(),
                                        _vm._v(" ESTADO")
                                      ]),
                                      _vm._v(" "),
                                      !_vm.editState
                                        ? _c(
                                            "div",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mr-4",
                                                                  attrs: {
                                                                    icon: "",
                                                                    small: ""
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      size: "16"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        $event.preventDefault()
                                                                        _vm.editState = true
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fas fa-pencil-alt"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    595849647
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v("Editar estado")
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-form",
                                        {
                                          ref: "formState",
                                          attrs: { "lazy-validation": "" }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "fill-height text-center px-3 pt-2"
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c("v-select", {
                                                    staticClass: "pt-2",
                                                    staticStyle: {
                                                      "font-size": "15px"
                                                    },
                                                    attrs: {
                                                      value:
                                                        _vm.getTicket.state,
                                                      items:
                                                        _vm.getActiveTicketStates,
                                                      readonly: !_vm.editState,
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      label: "Estado",
                                                      dense: ""
                                                    },
                                                    model: {
                                                      value: _vm.state,
                                                      callback: function($$v) {
                                                        _vm.state = $$v
                                                      },
                                                      expression: "state"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm.editState
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            rounded: "",
                                                            color: "accent"
                                                          },
                                                          on: {
                                                            click: _vm.saveState
                                                          }
                                                        },
                                                        [_vm._v("Guardar")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            rounded: ""
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.cancelState
                                                          }
                                                        },
                                                        [_vm._v("Cancelar")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.getTicket.contact_id
                                ? _c(
                                    "v-expansion-panel",
                                    [
                                      _c(
                                        "v-expansion-panel-header",
                                        {
                                          staticClass:
                                            "text--darken-2 font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            "\n                CONTACTO\n                "
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "pa-2 mr-4",
                                                                  attrs: {
                                                                    size: "16",
                                                                    icon: "",
                                                                    small: "",
                                                                    target:
                                                                      "_blank",
                                                                    to:
                                                                      "/contact/" +
                                                                      _vm
                                                                        .getTicket
                                                                        .contact_id
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      size: "16"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fas fa-external-link-alt"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    2777635992
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v("Ver contacto")
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              !_vm.editContact
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mr-4",
                                                                      attrs: {
                                                                        size:
                                                                          "16",
                                                                        icon:
                                                                          "",
                                                                        small:
                                                                          ""
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          size:
                                                                            "16"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            $event.preventDefault()
                                                                            _vm.editContact = true
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "fas fa-pencil-alt"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        3704900972
                                                      )
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "Editar contacto"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-expansion-panel-content",
                                        [
                                          _c(
                                            "v-form",
                                            {
                                              ref: "formContact",
                                              attrs: { "lazy-validation": "" }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "fill-height text-center px-3 pt-2"
                                                },
                                                [
                                                  _vm.editContact
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c("h4", [
                                                            _vm._v(
                                                              "Editar información del Contacto"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.editContact
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c("ContactForm", {
                                                            attrs: {
                                                              action: "update"
                                                            },
                                                            on: {
                                                              cancelInformation:
                                                                _vm.cancelContact
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-2",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              staticClass:
                                                                "fill-height text-center"
                                                            },
                                                            [
                                                              _vm._l(
                                                                _vm.contactFields,
                                                                function(
                                                                  contactField,
                                                                  i
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        key: i,
                                                                        staticClass:
                                                                          "py-1",
                                                                        attrs: {
                                                                          cols:
                                                                            "12"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            attrs: {
                                                                              value:
                                                                                _vm
                                                                                  .getContact[
                                                                                  contactField
                                                                                    .field
                                                                                ],
                                                                              label:
                                                                                contactField.label,
                                                                              readonly: !_vm.editContact,
                                                                              dense:
                                                                                ""
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        ],
                                                        1
                                                      )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticClass:
                                        "text--darken-2 font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        "\n                ETIQUETAS\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    [_c("Tags")],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticClass:
                                        "text--darken-2 font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        "\n                ASISTENCIA\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "fill-height text-center px-3"
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "d-flex",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _vm.getTicket.agent_id &&
                                              !_vm.loadSaveAgent
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-avatar",
                                                        {
                                                          attrs: {
                                                            size: "50px",
                                                            color: "black"
                                                          }
                                                        },
                                                        [
                                                          _vm.getAllAgentsMap[
                                                            _vm.getTicket
                                                              .agent_id
                                                          ].photo
                                                            ? _c("img", {
                                                                attrs: {
                                                                  src:
                                                                    _vm
                                                                      .getAllAgentsMap[
                                                                      _vm
                                                                        .getTicket
                                                                        .agent_id
                                                                    ].photo,
                                                                  alt: "Avatar"
                                                                }
                                                              })
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "white--text headline"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.getAllAgentsMap[
                                                                          _vm
                                                                            .getTicket
                                                                            .agent_id
                                                                        ].full_name.substring(
                                                                          0,
                                                                          1
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-avatar",
                                                        {
                                                          attrs: {
                                                            size: "50px",
                                                            color: "black"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "white--text headline"
                                                            },
                                                            [_vm._v("-")]
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                              _vm._v(" "),
                                              _vm.getAgent.role ===
                                              _vm.$roles.ADVISOR
                                                ? _c("v-text-field", {
                                                    staticClass: "pl-2",
                                                    attrs: {
                                                      label: "Encargado",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.getAgent.full_name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.getAgent,
                                                          "full_name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "getAgent.full_name"
                                                    }
                                                  })
                                                : _c("v-select", {
                                                    staticClass: "pl-2 w95",
                                                    attrs: {
                                                      items:
                                                        _vm.getAgentsToArray,
                                                      disabled:
                                                        _vm.loadSaveAgent,
                                                      "item-text": "full_name",
                                                      "item-value": "uid",
                                                      value:
                                                        _vm.getTicket.agent_id,
                                                      label: "Encargado",
                                                      placeholder: "Sin asignar"
                                                    },
                                                    model: {
                                                      value: _vm.agentTicket,
                                                      callback: function($$v) {
                                                        _vm.agentTicket = $$v
                                                      },
                                                      expression: "agentTicket"
                                                    }
                                                  })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticClass:
                                        "text--darken-2 font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        "\n                FUENTE\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "fill-height text-center px-3"
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "size",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  "\n                      Obtenido desde "
                                                ),
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(_vm.getTicket.origin)
                                                  )
                                                ])
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.getTicket.created_uid
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "d-flex",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-avatar",
                                                        {
                                                          attrs: {
                                                            size: "50px",
                                                            color: "black"
                                                          }
                                                        },
                                                        [
                                                          _vm.getAllAgentsMap[
                                                            _vm.getTicket
                                                              .created_uid
                                                          ].photo
                                                            ? _c("img", {
                                                                attrs: {
                                                                  src:
                                                                    _vm
                                                                      .getAllAgentsMap[
                                                                      _vm
                                                                        .getTicket
                                                                        .created_uid
                                                                    ].photo,
                                                                  alt: "Avatar"
                                                                }
                                                              })
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "white--text headline"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.getAllAgentsMap[
                                                                          _vm
                                                                            .getTicket
                                                                            .created_uid
                                                                        ].full_name.substring(
                                                                          0,
                                                                          1
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-text-field", {
                                                    staticClass: "pl-2 w95",
                                                    attrs: {
                                                      value:
                                                        _vm.getAllAgentsMap[
                                                          _vm.getTicket
                                                            .created_uid
                                                        ].full_name,
                                                      label: "Registrado por:",
                                                      readonly: ""
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pa-1", attrs: { cols: "12", md: "7" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "mt-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "grey--text text--darken-2 font-weight-bold pa-4",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _vm._v(
                                    "\n              HISTORIAL DE SEGUIMIENTO A LA OPORTUNIDAD\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12 pa-4 pt-0" } },
                                [
                                  _c("CommentForm", {
                                    attrs: { "ticket-id": _vm.ticket.id }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-divider"),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("Timeline", {
                                    attrs: { "ticket-id": _vm.ticket.id }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }