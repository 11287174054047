import * as MutationsType from "./mutationsType";
import requestAxios from "@/helpers/request_axios";

const { devicesCollection } = require("@/config/firebase");

export default {
  async getDevicesByAgentId({ commit }, payload) {
    let snap = await devicesCollection.doc(payload.agent_id).get();
    commit(MutationsType.SET_DEVICES, snap.exists ? { id: snap.id, ...snap.data() } : {});
  },
  // eslint-disable-next-line
  async updateNotificationToken({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/device/notifications",
        method: "POST",
        data: payload,
      });
      return response.data;
    } catch (error) {
      console.error("device/updateNotificationToken: ", error);
      return error.response.data;
    }
  },
};
