import algoliasearch from "algoliasearch";
import { createNullCache } from "@algolia/cache-common";
import { algolia } from "Config/config";

const searchClient = algoliasearch(algolia.id, algolia.key, {
  responsesCache: createNullCache(),
  requestsCache: createNullCache(),
});

const ticketsIndex = searchClient.initIndex("tickets");
const contactsIndex = searchClient.initIndex("contacts");

export { ticketsIndex, contactsIndex };
