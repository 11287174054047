import Vue from "vue";
import * as MutationsType from "./mutationsType";
/* eslint-disable no-param-reassign */
export default {
  [MutationsType.SET_TICKET_STATES](state, payload) {
    state.ticketStates = payload;
  },
  [MutationsType.ADD_TICKET_STATE](state, payload) {
    state.ticketStates.push(payload);
  },
  [MutationsType.UPDATE_TICKET_STATE](state, payload) {
    let ind = state.ticketStates.findIndex((t) => {
      return t.id == payload.id;
    });
    state.ticketStates[ind].name = payload.name;
    state.ticketStates[ind].color = payload.color;
  },
  [MutationsType.REMOVE_TICKET_STATE](state, payload) {
    let ind = state.ticketStates.findIndex((t) => t.id === payload);
    Vue.delete(state.ticketStates, ind);
  },
};
