export default {
  getTimeline: (state) => (id) => {
    return state.timeline
      .filter((d) => d.ticketId === id)
      .sort((a, b) => b.created_at.toDate() - a.created_at.toDate());
  },
  getContactTimeline: (state) => (id) => {
    return state.contactTimeline
      .filter((d) => d.contactId === id)
      .sort((a, b) => b.created_at.toDate() - a.created_at.toDate());
  },
};
