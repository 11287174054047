import { render, staticRenderFns } from "./MenuOptions.vue?vue&type=template&id=e7b42ae4&scoped=true&"
import script from "./MenuOptions.vue?vue&type=script&lang=js&"
export * from "./MenuOptions.vue?vue&type=script&lang=js&"
import style0 from "./MenuOptions.vue?vue&type=style&index=0&id=e7b42ae4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7b42ae4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/angyqr/Documents/una/always-with-you/hosting/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e7b42ae4')) {
      api.createRecord('e7b42ae4', component.options)
    } else {
      api.reload('e7b42ae4', component.options)
    }
    module.hot.accept("./MenuOptions.vue?vue&type=template&id=e7b42ae4&scoped=true&", function () {
      api.rerender('e7b42ae4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/inc/MenuOptions.vue"
export default component.exports