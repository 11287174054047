var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "700" },
      model: {
        value: _vm.dialogMessenger,
        callback: function($$v) {
          _vm.dialogMessenger = $$v
        },
        expression: "dialogMessenger"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "py-3" },
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("v-icon", { attrs: { color: "#22A0EF" } }, [
                _vm._v("fab fa-facebook-messenger")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "ml-2" }, [
                _vm._v("Conectar con Facebook Messenger")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h4", { staticClass: "font-weight-thin" }, [
                      _vm._v(
                        "\n            Está integración le permitirá generar oportunidades con cada cliente que se contacte con su negocio a\n            traves de su página de Facebook.\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h4", { staticClass: "font-weight-thin" }, [
                      _c(
                        "span",
                        { staticClass: "circle primary white--text mr-2" },
                        [_vm._v("1")]
                      ),
                      _vm._v(" Deberá\n            "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("iniciar sesión")
                      ]),
                      _vm._v(" con Facebook, y luego\n            "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("seleccionar la página")
                      ]),
                      _vm._v(" que desea conectar con Apolo CRM.\n          ")
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pt-2", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "caption",
                          attrs: { dense: "", text: "", type: "info" }
                        },
                        [
                          _vm._v(
                            "\n            El usuario que va a iniciar sesión debe ser administrador de la "
                          ),
                          _c("strong", [_vm._v("página de Facebook")]),
                          _vm._v(" que va\n            a vincular.\n          ")
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.user || _vm.status === false
                    ? _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                dark: "",
                                rounded: "",
                                color: "#365EAB"
                              },
                              on: { click: _vm.loginWithFacebook }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: { small: "", color: "white" }
                                },
                                [_vm._v("fab fa-facebook-square")]
                              ),
                              _vm._v(
                                "\n            Conectar con Facebook\n          "
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.message
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-alert",
                            {
                              attrs: {
                                dense: "",
                                text: "",
                                type: _vm.status ? "success" : "error"
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.message) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "text-right", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", rounded: "", text: "" },
                          on: { click: _vm.closeDialogMessenger }
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _vm._v(" "),
                      !_vm.status
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                rounded: "",
                                disabled:
                                  _vm.user === null || _vm.status === false,
                                loading: _vm.loading
                              },
                              on: { click: _vm.connect }
                            },
                            [_vm._v("Conectar")]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", rounded: "" },
                              on: { click: _vm.closeDialogMessenger }
                            },
                            [_vm._v("Listo")]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }