<template>
  <v-btn :href="`https://api.whatsapp.com/send?phone=${getPhone}`" target="_blank" text icon @click.stop>
    <v-icon dark small>fab fa-whatsapp</v-icon>
  </v-btn>
</template>
<script>
export default {
  props: {
    number: {
      type: String,
      default: "",
    },
  },
  computed: {
    getPhone() {
      if (this.number.includes("+")) return this.number;
      return `+51 ${this.number}`;
    },
  },
};
</script>
