export default {
  getAgent(state) {
    return state.agent;
  },
  getAgents(state) {
    return state.agents;
  },
  getAllAgentsArray(state) {
    return state.allAgents;
  },
  getAllAgentsMap(state) {
    return state.allAgentsMap;
  },
  getAgentsToArray(state) {
    let agent, agents;
    if (state.agents) {
      agents = [];
      for (let a in state.agents) {
        agent = state.agents[a];
        if (agent.active) agents.push({ uid: a, ...agent });
      }
    }
    return agents;
  },
  getAgentRoles(state) {
    return state.agentRoles;
  },
  getAgentRolesToArray(state) {
    let roles;
    if (state.agentRoles) {
      roles = [];
      for (let r in state.agentRoles) {
        roles.push(state.agentRoles[r]);
      }
    }
    return roles;
  },
  getAgentSelected(state) {
    return state.agentSelected;
  },
  getAgentId(state) {
    return state.agentId;
  },
  showAgentDetailDrawer(state) {
    return state.showAgentDetailDrawer;
  },
  getMenuOptions(state) {
    return state.menu;
  },
};
