<template>
  <v-list dense>
    <v-divider></v-divider>

    <v-list-item v-if="getAgent" two-line class="px-3">
      <v-list-item-avatar color="primary" size="26">
        <v-avatar v-if="getAgent.photo">
          <v-img :src="getAgent.photo"></v-img>
        </v-avatar>
        <v-avatar v-else color="primary">
          <span class="white--text">
            {{ getAgent.full_name ? getAgent.full_name.substring(0, 1) : "" }}
          </span>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ getAgent.full_name }}</v-list-item-title>
        <v-list-item-subtitle>{{ getAgent.email }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item link :to="'/settings'">
      <v-list-item-icon class="my-auto">
        <v-icon small>fas fa-cog</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Configuraciones</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item href="https://apolocrm.atlassian.net/servicedesk/customer/portal/1/group/1/create/1" target="_blank">
      <v-list-item-icon class="my-auto">
        <v-icon small>far fa-question-circle</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Soporte</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SettingsOptions",
  computed: {
    ...mapGetters(["getAgent"]),
  },
};
</script>
