import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_ZONES](state, payload) {
    state.zones = payload;
  },
  [MutationsType.ADD_ZONE](state, payload) {
    state.zones.push(payload);
  },
};
