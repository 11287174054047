import requestAxios from "@/helpers/request_axios";
import * as MutationsType from "./mutationsType";

const { ordersCollection } = require("@/config/firebase");

export default {
  async getSaleById({ commit }, payload) {
    let doc = await ordersCollection.doc(payload.sale_id).get();
    commit(MutationsType.SET_SALE, { id: doc.id, ...doc.data() });
  },
  async getAllSales({ commit }) {
    let querySnapshot = await ordersCollection.orderBy("created_at", "desc").get();
    commit(MutationsType.SET_SALES, []);
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        commit(MutationsType.ADD_SALE, { id: doc.id, ...doc.data() });
      });
    }
  },
  // eslint-disable-next-line
  async downloadSales({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/sale/download",
        method: "GET",
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async exportSaleTemplate({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/sale/export-template",
        method: "GET",
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async importSales({ commit }, payload) {
    try {
      let formdata = new FormData();
      formdata.append("file", payload.file);
      let response = await requestAxios({
        url: "/awy/api/v1/sale/import",
        method: "POST",
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
