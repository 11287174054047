export default {
  getSale(state) {
    return state.sale;
  },
  getSales(state) {
    return state.sales;
  },
  getPaidStatus(state) {
    return state.paidStatus;
  },
  getPaidStatusToSelect(state) {
    return [{ id: 0, name: "todos" }, ...state.paidStatus];
  },
  getProducts(state) {
    return state.products;
  },
};
