<template>
  <v-dialog v-model="dialogMessenger" persistent max-width="700">
    <v-card class="py-3">
      <v-card-title class="headline">
        <v-icon color="#22A0EF">fab fa-facebook-messenger</v-icon>
        <span class="ml-2">Conectar con Facebook Messenger</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h4 class="font-weight-thin">
              Está integración le permitirá generar oportunidades con cada cliente que se contacte con su negocio a
              traves de su página de Facebook.
            </h4>
          </v-col>
          <v-col cols="12">
            <h4 class="font-weight-thin">
              <span class="circle primary white--text mr-2">1</span> Deberá
              <span class="font-weight-bold">iniciar sesión</span> con Facebook, y luego
              <span class="font-weight-bold">seleccionar la página</span> que desea conectar con Apolo CRM.
            </h4>
          </v-col>
          <v-col cols="12" class="pt-2">
            <v-alert dense text type="info" class="caption">
              El usuario que va a iniciar sesión debe ser administrador de la <strong>página de Facebook</strong> que va
              a vincular.
            </v-alert>
          </v-col>
          <v-col v-if="!user || status === false" cols="12" class="text-center">
            <v-btn dark rounded color="#365EAB" @click="loginWithFacebook">
              <v-icon small color="white" class="mr-2">fab fa-facebook-square</v-icon>
              Conectar con Facebook
            </v-btn>
          </v-col>
          <v-col v-if="message" cols="12">
            <v-alert dense text :type="status ? 'success' : 'error'">
              {{ message }}
            </v-alert>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn color="primary" rounded text @click="closeDialogMessenger">Cancelar</v-btn>
            <v-btn
              v-if="!status"
              color="primary"
              rounded
              :disabled="user === null || status === false"
              :loading="loading"
              @click="connect"
              >Conectar</v-btn
            >
            <v-btn v-else color="primary" rounded @click="closeDialogMessenger">Listo</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "WebhookMessenger",
  props: {
    dialogMessenger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: null,
      accounts: null,
      loading: false,
      message: null,
      status: null,
    };
  },
  watch: {
    user(value) {
      if (value) this.connect();
    },
  },
  async mounted() {
    await this.loadFacebookSDK(document, "script", "facebook-jssdk");
    await this.initFacebook();
  },
  methods: {
    ...mapActions(["connectWebhook"]),
    async loginWithFacebook() {
      window.FB.login(
        function (response) {
          if (response.authResponse) this.user = response.authResponse;
        }.bind(this),
        {
          scope: ["pages_messaging", "pages_show_list"],
          enable_profile_selector: true,
          auth_type: "rerequest",
          return_scopes: true,
        },
      ).bind(this);
      return false;
    },
    async connect() {
      try {
        this.loading = true;
        let response = await this.connectWebhook({
          webhook_type: "messenger",
          data: { user: this.user },
        });

        if (response.error) throw response.error;

        this.status = true;
        this.message = response.message;
      } catch (error) {
        this.status = false;
        this.message = error.message || "Hubo un error al conectar";
      } finally {
        this.loading = false;
      }
    },
    closeDialogMessenger() {
      this.user = null;
      this.status = null;

      this.$emit("update:dialogMessenger", false);
    },
    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: "707872003409723",
          cookie: true,
          version: "v8.0",
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
  },
};
</script>
