<template>
  <div>
    <v-list-item key="webhooks" @click.stop="toggle = !toggle">
      <v-list-item-avatar>
        <v-icon>fa-network-wired</v-icon>
      </v-list-item-avatar>
      <v-list-item-content class="py-4">
        <v-list-item-title>Integraciones</v-list-item-title>
        <v-list-item-subtitle>Conecta tus canales digitales con el CRM</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>
          {{ !toggle ? "fas fa-angle-down" : "fas fa-angle-up" }}
        </v-icon>
      </v-list-item-action>
    </v-list-item>
    <v-container v-if="getWebhooks && toggle" id="webhooks">
      <v-col v-if="channels_enabled.length > 0" cols="12">
        <h5 class="mb-4">Canales disponibles</h5>
        <v-row>
          <v-col v-for="(channel, i) in channels_enabled" :key="i" cols="6" sm="4" md="2">
            <v-card class="py-3" @click="openConnect(channel.id)">
              <!-- <v-avatar class="profile" color="white" size="80" tile> -->
              <v-img :src="require(`@/assets/social_icons/${channel.icon}`)" width="40" class="mx-auto"></v-img>
              <!-- </v-avatar> -->
              <div class="d-flex mt-2">
                <span class="text-center mx-auto caption">{{ channel.name }}</span>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <h5 class="mb-2">Canales conectados</h5>
        <v-col v-if="getWebhooks.length === 0" cols="12" class="text-center">
          <h4 class="font-weight-thin">
            No tiene canales conectados, vincule uno para generar más oportunidades a su negocio.
          </h4>
        </v-col>
        <v-col v-else cols="12" class="pa-0">
          <v-row>
            <template v-for="(channel, i) in getWebhooks">
              <v-col :key="i" cols="12" class="py-1">
                <v-card class="rounded-lg pa-2">
                  <v-row class="px-3">
                    <v-col cols="12" sm="6" md="6" class="d-flex pa-0">
                      <div class="my-auto px-2">
                        <v-img
                          :src="require(`@/assets/social_icons/${getWebhooksTypes[channel.id].icon}`)"
                          width="35"
                          class="mx-auto"
                        ></v-img>
                      </div>
                      <h5 class="font-weight-thin text--overflow my-auto">{{ getWebhooksTypes[channel.id].name }}</h5>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="text-right d-flex">
                      <h5 class="font-weight-thin grey--text text--darken-2 my-auto">
                        <v-icon x-small class="mr-2" :color="channel.active ? 'success' : 'warning'"
                          >fas fa-circle</v-icon
                        >{{ channel.active ? "Activado" : "Desactivado" }}
                      </h5>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="show_channel != channel.id"
                        text
                        small
                        color="primary"
                        @click="show_channel = channel.id"
                        >Ver detalle</v-btn
                      >
                      <v-btn v-else text small color="primary" @click="show_channel = null">Ocultar</v-btn>
                      <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn x-small icon class="my-auto" v-bind="attrs" v-on="on">
                            <v-icon x-small>fas fa-ellipsis-v</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item @click="openEnable(channel.id)">
                            <v-list-item-title>{{ channel.active ? "Desactivar" : "Activar" }}</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="openDelete(channel.id)">
                            <v-list-item-title>Eliminar</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                    <v-col v-if="show_channel === channel.id" cols="12">
                      <v-row>
                        <v-col v-if="show_channel === 'messenger'" cols="12">
                          <h5 class="pb-2">Páginas</h5>
                          <template v-for="(page, j) in channel.pages">
                            <v-col :key="j" cols="12" class="py-1">
                              <h5 class="font-weight-thin">- {{ page.name }}</h5>
                            </v-col>
                          </template>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-col>

      <v-col cols="12" class="mt-4">
        <h5 class="mb-4">Otras integraciones</h5>
        <v-row>
          <v-col v-for="(integration, i) in integrations_enabled" :key="i" cols="6" sm="4" md="2">
            <v-card class="py-3" @click="openConnect(integration.id)">
              <v-img :src="integration.icon" width="40" class="mx-auto"></v-img>
              <div class="d-flex mt-2">
                <span class="text-center mx-auto caption">{{ integration.name }}</span>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <WebhookMessenger v-if="dialog_messenger" :dialog-messenger.sync="dialog_messenger" />
      <ZoomWebinars v-if="dialog_zoom_webinars" :dialog-zoom-webinars.sync="dialog_zoom_webinars" />

      <!-- Modals -->
      <v-dialog v-if="channel_selected" v-model="dialog_enabled" persistent max-width="400">
        <v-card class="py-3">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h3 class="font-weight-thin">
                  ¿Desea
                  <span class="font-weight-bold">{{ channel_selected.active ? "desactivar" : "activar" }}</span> el
                  canal {{ getWebhooksTypes[channel_selected.id].name }}?
                </h3>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mr-2">
            <v-spacer></v-spacer>
            <v-btn rounded text @click="dialog_enabled = false">Cancelar</v-btn>
            <v-btn :loading="loading" color="primary" rounded @click="enable(channel_selected)">{{
              channel_selected.active ? "Desactivar" : "Activar"
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-if="channel_selected" v-model="dialog_delete" persistent max-width="400">
        <v-card class="py-3">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h3 class="font-weight-thin">
                  ¿Está seguro que
                  <span class="font-weight-bold"
                    >desea eliminar la conección con {{ getWebhooksTypes[channel_selected.id].name }}</span
                  >? Con esta acción ya no se recibirán oportunidades desde este canal.
                </h3>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mr-2">
            <v-spacer></v-spacer>
            <v-btn rounded text @click="dialog_delete = false">Cancelar</v-btn>
            <v-btn :loading="loading" color="red" rounded dark @click="remove(channel_selected)">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import WebhookMessenger from "@/views/settings/webhooks/WebhookMessenger";
import ZoomWebinars from "@/views/settings/webhooks/ZoomWebinars";

export default {
  name: "Webhooks",
  components: {
    WebhookMessenger,
    ZoomWebinars,
  },
  data() {
    return {
      toggle: false,
      channel_selected: null,
      show_channel: null,
      channels_enabled: null,
      integrations_enabled: [
        {
          id: "zoom_webinars",
          name: "Zoom Webinars",
          icon: require("@/assets/social_icons/zoom.png"),
        },
      ],
      dialog_messenger: false,
      dialog_zoom_webinars: false,
      dialog_enabled: false,
      dialog_delete: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getWebhooks", "getWebhooksTypes"]),
  },
  watch: {
    getWebhooks(value) {
      let channels = [];

      for (let c in this.getWebhooksTypes) if (!value.find((w) => w.id === c)) channels.push(this.getWebhooksTypes[c]);

      this.channels_enabled = channels;
    },
  },
  async created() {
    await this.getAllWebhooks();
  },
  methods: {
    ...mapActions(["getAllWebhooks", "enableWebhook", "deleteWebhook"]),
    openConnect(channel) {
      switch (channel) {
        case "messenger":
          this.dialog_messenger = true;
          break;
        case "zoom_webinars":
          this.dialog_zoom_webinars = true;
          break;
      }
    },
    openEnable(channel) {
      this.channel_selected = this.getWebhooks.find((c) => c.id === channel);
      this.dialog_enabled = true;
    },
    openDelete(channel) {
      this.channel_selected = this.getWebhooks.find((c) => c.id === channel);
      this.dialog_delete = true;
    },
    async enable(channel) {
      try {
        this.loading = true;

        await this.enableWebhook({ webhook_type: channel.id });

        this.dialog_enabled = false;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async remove(channel) {
      try {
        this.loading = true;

        await this.deleteWebhook({ webhook_type: channel.id });

        this.dialog_delete = false;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
