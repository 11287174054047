<template>
  <v-app id="contacts">
    <div v-if="getAgent && getAgents && getContacts">
      <v-row class="d-flex align-center pt-4 pt-sm-10">
        <v-col cols="12" sm="6" md="9">
          <p class="text-h4 primary--text mb-0 font-weight-black hidden-sm-and-down">Contactos</p>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="d-flex justify-md-end justify-space-between">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon large class="mr-0 hidden-md-and-up" @click="showFilters = !showFilters" v-on="on">
                <v-icon size="16">fas fa-filter</v-icon>
              </v-btn>
            </template>
            <span>{{ showFilters ? "Ocultar" : "Mostrar" }} filtros</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-btn :loading="loadingExport" outlined color="primary" dark normal class="mr-4 my-auto" @click="exportData">
            Descargar
            <v-icon right size="16">fa-file-download</v-icon>
          </v-btn>
          <v-menu offset-y bottom left>
            <template v-slot:activator="{ on }">
              <v-btn icon outlined class="my-auto" v-on="on">
                <v-icon size="16">fa fa-ellipsis-v</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item to="/contacts/importer">
                <v-list-item-title>Importar contactos</v-list-item-title>
              </v-list-item>
              <v-list-item to="/contacts/importer-activities">
                <v-list-item-title>Importar actividades</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <div class="hidden-lg-and-up">
        <v-btn to="/contact/register" color="primary" elevation-12 block>
          <span> <v-icon size="16" class="mr-2">fa-plus</v-icon> Agregar </span>
        </v-btn>
      </div>
      <v-row class="d-flex align-center py-2 py-sm-4">
        <template v-for="(f, i) in customFilters">
          <v-col
            v-if="showFilters"
            :key="i"
            :cols="f.flex_xs || '12'"
            :sm="f.flex_md || '6'"
            :md="f.flex_md || '3'"
            class="py-0 mb-1 mb-sm-0"
          >
            <v-card class="py-2 px-4 elevation-3 rounded-lg">
              <v-select
                :value="f.value"
                :items="f.items"
                :label="f.name"
                :readonly="f.readonly_advisor && getAgent.role === $roles.ADVISOR"
                :item-text="f.show_attr || 'name'"
                item-value="id"
                class="px-1"
                @change="applyFilter(f.id, $event)"
              ></v-select>
            </v-card>
          </v-col>
        </template>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="6" md="3" class="py-0">
          <v-card class="px-4 elevation-3 rounded-lg py-1">
            <v-text-field
              v-model="query"
              label="Buscar"
              dense
              solo
              single-line
              hide-details
              flat
              class="d-flex align-center imput-icon"
              append-icon="fa fa-search"
              @input="loadContacts"
            ></v-text-field>
          </v-card>
        </v-col>
      </v-row>
      <v-card class="mb-5 pb-5 rounded-lg">
        <v-progress-linear
          :active="contacts === null"
          :indeterminate="true"
          absolute
          top
          color="secondary accent-4"
        ></v-progress-linear>
        <v-col v-if="getContacts && contacts" cols="12" class="pa-4">
          <v-data-table
            :headers="headers"
            :items="contacts"
            :items-per-page="50"
            :mobile-breakpoint="0"
            sort-by
            dense
            hide-default-footer
            class="text-headers-colors"
          >
            <template v-slot:item.created_at="{ item }">
              {{ $formatTimestamp(item.created_at) }}
            </template>
            <template v-slot:item.agent_id="{ item }">
              {{ item.agent_id !== "none" && item.agent_id ? getAgents[item.agent_id].name : "Sin asignar" }}
            </template>
            <template v-slot:item.operations="{ item }">
              <v-btn text icon :to="`/contact/${item.id}`" target="_blank" @click.stop>
                <v-icon color="primary" dark small>fas fa-external-link-alt</v-icon>
              </v-btn>
            </template>
            <v-alert v-slot:no-results :value="true" color="error" icon="warning"
              >Your search for "{{ search }}" found no results.</v-alert
            >
          </v-data-table>
          <v-row class="pa-0">
            <v-spacer></v-spacer>
            <v-col cols="12" md="6" class="text-center">
              <v-pagination
                v-model="page"
                :length="total_page"
                prev-icon="fas fa-angle-left"
                next-icon="fas fa-angle-right"
                total-visible="6"
                @input="loadContacts"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="3" class="d-flex align-center justify-end">
              <h5 class="font-weight-thin">
                Total de contactos: {{ total_contacts - getContacts.length + contacts.length }}
              </h5>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
      <v-btn
        to="/contact/register"
        fixed
        bottom
        right
        x-large
        color="primary"
        elevation-12
        rounded
        class="hidden-sm-and-down"
      >
        <span> <v-icon size="16" class="mr-2">fa-plus</v-icon> Agregar </span>
      </v-btn>
    </div>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { getCountryList } from "@/helpers";
import config from "Config/config";
import { contactsIndex } from "@/config/algolia";
import { formatFilters } from "@/helpers/format_algoliaparams";

export default {
  name: "Contacts",
  data() {
    return {
      agent: null,
      agents: null,
      contacts: null,
      countryArray: null,
      customFilters: config.contactFilters,
      filters_selected: {},
      query: "",
      filters: "",
      page: 1,
      total_page: 1,
      total_contacts: 0,
      showFilters: true,
      headers: config.tableContact,
      loadingExport: false,
      loadingContacts: false,
    };
  },
  computed: {
    ...mapGetters(["getAgent", "getAgents", "getAgentsToArray", "getContacts", "getNewContacts"]),
  },
  watch: {
    getAgents(value) {
      if (value) {
        if ([this.$roles.ADVISOR, this.$roles.SUPERVISOR].includes(this.getAgent.role))
          this.filters_selected["agent_id"] = { value: this.getAgent.uid };

        this.filters = formatFilters(this.filters_selected);
        this.loadUI();
      }
    },
    getContacts(value) {
      this.contacts = [...value];
    },
    getNewContacts(value) {
      if (value && this.query === "" && this.filters === formatFilters(this.filters_selected)) {
        for (const contact of value) {
          let ind = this.contacts.findIndex((t) => t.id === contact.id);
          if (ind >= 0) Vue.set(this.contacts, ind, contact);
          else this.contacts.unshift(contact);
        }
      }
    },
  },
  async created() {
    this.countryArray = await getCountryList();
    await this.getAllAgents();
  },
  methods: {
    ...mapActions(["getAllAgents", "setGroupContacts", "downloadContacts", "getAllNewContacts"]),
    async loadUI() {
      let data = await contactsIndex.search(this.query, {
        facets: Object.keys(this.customFilters),
        filters: this.filters,
      });

      this.contacts = data.hits || [];
      this.total_page = data.nbPages;
      this.total_contacts = data.nbHits;
      this.page = data.page + 1;

      this.setGroupContacts({ contacts_selected: this.contacts });
      this.getAllNewContacts({ last_contact: this.contacts[0] });

      this.loadFilters(data.facets);
    },
    loadFilters(facets) {
      for (let filter in facets) {
        let items = [{ id: "", name: "Todos", full_name: "Todos" }];

        if (filter === "country") items.push(...this.countryArray);
        else if (filter === "agent_id") items.push({ id: "none", full_name: "Sin asignar" }, ...this.getAgentsToArray);
        else
          for (let field in facets[filter]) {
            items.push({ id: field, name: field });
          }

        this.customFilters[filter].items = items;
        this.customFilters[filter].value = this.filters_selected[filter] ? this.filters_selected[filter].value : "";
      }
    },
    applyFilter(filter_id, filter_value) {
      this.customFilters[filter_id].value = filter_value;
      this.page = 1;
      this.filters = formatFilters(this.customFilters);

      this.loadContacts();
    },
    async loadContacts() {
      this.loadingContacts = true;

      let data = await contactsIndex.search(this.query, {
        facets: Object.keys(this.customFilters),
        filters: this.filters,
        page: this.page - 1,
      });

      this.contacts = data.hits || [];
      this.total_page = data.nbPages;
      this.total_contacts = data.nbHits;
      this.page = data.page + 1;
      this.loadingContacts = false;

      this.setGroupContacts({ contacts_selected: this.contacts });
    },
    async exportData() {
      try {
        this.loadingExport = true;
        let response = await this.downloadContacts();
        let url = window.URL.createObjectURL(new Blob([response]));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `apolocrm-contacts-${this.$moment().format("DD-MM-YYYY-hh-mm")}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingExport = false;
      }
    },
  },
};
</script>
<style>
.max-w180 {
  max-width: 180px;
}
.v-avatar {
  cursor: pointer;
}
.text-decoration-none {
  text-decoration: none;
}
th[aria-label="Email: Not sorted."],
.hidden {
  display: none;
}
.v-btn {
  text-transform: none;
}
</style>
