import * as MutationsType from "./mutationsType";
import { contactsCollection } from "@/config/firebase";
import requestAxios from "@/helpers/request_axios";
import { roles } from "@/helpers/get_constants";
import getCurrentUser from "@/helpers/get_current_user";

export default {
  async getContactById({ commit }, payload) {
    try {
      await contactsCollection.doc(`${payload.contact_id}`).onSnapshot(async (doc) => {
        let contact = { id: doc.id, ...doc.data() };
        commit(MutationsType.SET_CONTACT, contact);
      });
    } catch (error) {
      console.error("getContactById: ", error);
    }
  },
  async getAllContacts({ commit }) {
    let agent = await getCurrentUser();
    let reference = contactsCollection;

    if ([roles.ADVISOR, roles.SUPERVISOR].includes(agent.role))
      reference = reference.where("agent_id", "==", agent.uid);

    await reference.orderBy("created_at", "desc").onSnapshot(async (snap) => {
      let data;
      commit(MutationsType.SET_CONTACTS, []);
      snap.docs.forEach((doc) => {
        data = doc.data();
        commit(MutationsType.ADD_CONTACT, { id: doc.id, ...data });
      });
    });
  },
  async getAllNewContacts({ commit }, payload) {
    try {
      let agent = await getCurrentUser();
      let reference = contactsCollection;

      if ([roles.ADVISOR, roles.SUPERVISOR].includes(agent.role))
        reference = reference.where("agent_id", "==", agent.uid);

      reference = reference.orderBy("created_at", "asc");

      if (payload.last_contact) {
        const start = await contactsCollection.doc(payload.last_contact.id).get();
        reference = reference.startAfter(start);
      }

      await reference.onSnapshot(async (snap) => {
        let newContacts = [];

        snap.docs.forEach((doc) => {
          newContacts.push({ id: doc.id, ...doc.data() });
        });

        commit(MutationsType.SET_NEW_CONTACTS, newContacts);
      });
    } catch (error) {
      console.error("getAllNewContacts: ", error);
    }
  },
  async setGroupContacts({ commit }, payload) {
    commit(MutationsType.SET_CONTACTS, payload.contacts_selected);
  },
  // eslint-disable-next-line
  async createContact({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/contact",
        method: "POST",
        data: {
          origin: "agent",
          ...payload.contact,
        },
      });
      return response.data;
    } catch (error) {
      console.error("createContact: ", error);
      return error.response.data;
    }
  },
  // eslint-disable-next-line
  async setContactAgent({ commit }, payload) {
    try {
      await contactsCollection.doc(payload.contact_id).update({
        agent_id: payload.agent.id,
        agent: {
          uid: payload.agent.id,
          full_name: payload.agent.full_name,
          email: payload.agent.email,
        },
      });
    } catch (error) {
      console.error("setContactAgent: ", error);
    }
  },
  // eslint-disable-next-line
  async setContactTerms({ commit }, payload) {
    try {
      return await requestAxios({
        url: "/awy/api/v1/contact/set-terms",
        method: "POST",
        data: payload,
      });
    } catch (error) {
      console.error("setContactTerms: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async updateContact({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/contact/${payload.contact_id}`,
        method: "PATCH",
        data: payload.data,
      });
      return response.data;
    } catch (error) {
      console.error("updateContact: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async downloadContacts({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/contact/download",
        method: "GET",
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      console.error("downloadContacts: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async exportContactTemplate({ commit }) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/contact/export-template",
        method: "GET",
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      console.error("exportContactTemplate: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async importContacts({ commit }, payload) {
    try {
      let formdata = new FormData();
      formdata.append("file", payload.file);
      let response = await requestAxios({
        url: "/awy/api/v1/contact/import",
        method: "POST",
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      console.error("importContacts: ", error);
      throw error.response.data;
    }
  },
};
