var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    { key: "notifications", on: { click: _vm.assign_tickets_m } },
    [
      _c(
        "v-list-item-avatar",
        [
          _c("v-icon", {
            class: _vm.assign_tickets ? "far fa-bell" : "far fa-bell-slash",
            attrs: { text: "Notificationes" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-list-item-content",
        [
          _c("v-list-item-title", [_vm._v("Notificationes")]),
          _vm._v(" "),
          _c("v-list-item-subtitle", [
            _vm._v(
              "Permitir notificaciones cada vez que se me asigne un nueva oportunidad"
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-list-item-action",
        [
          _c("v-switch", {
            attrs: {
              loading: _vm.load["assign_tickets"],
              disabled:
                !_vm.allow_receive_notification || _vm.load["assign_tickets"]
            },
            on: { click: _vm.assign_tickets_m },
            model: {
              value: _vm.assign_tickets,
              callback: function($$v) {
                _vm.assign_tickets = $$v
              },
              expression: "assign_tickets"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }