import Vue from "vue";
import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_TEAMS](state, payload) {
    state.teams = payload;
  },
  [MutationsType.ADD_TEAM](state, payload) {
    state.teams.push(payload);
  },
  [MutationsType.UPDATE_TEAM](state, payload) {
    let ind = state.teams.findIndex((team) => team.id === payload.id);
    if (ind >= 0) Vue.set(state.teams, ind, { ...state.teams[ind], ...payload });
  },
};
