var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      style: _vm.cssProps,
      attrs: {
        permanent: "",
        "elevation-0": "",
        "enable-resize-watcher": "",
        "mini-variant": _vm.show,
        app: "",
        "mobile-breakpoint": "960"
      },
      on: {
        "update:miniVariant": function($event) {
          _vm.show = $event
        },
        "update:mini-variant": function($event) {
          _vm.show = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "append",
          fn: function() {
            return [_c("SettingsOptions")]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.drawer,
        callback: function($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer"
      }
    },
    [
      _c(
        "v-list-item",
        { staticClass: "px-2 py-6", attrs: { dense: "" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "px-4",
              attrs: { icon: "", height: "50" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  _vm.show = !_vm.show
                }
              }
            },
            [_c("v-icon", { attrs: { size: "16" } }, [_vm._v("fa fa-bars")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item-content",
            { staticClass: "pr-16 pl-2 py-0" },
            [
              _c("v-img", {
                attrs: { src: _vm.getLogoUrl, height: "50", contain: "" }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("MenuOptions")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }