<template>
  <div>
    <v-row class="justify-end mr-0 pb-1">
      <v-btn
        rounded
        color="primary"
        @click="
          resetFormState();
          dialog_enable = true;
        "
      >
        <v-icon size="16">fa-plus</v-icon>
        <span class="hidden-sm-and-down ml-2">Agregar estado</span>
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  #
                </th>
                <th class="text-left">
                  Nombre
                </th>
                <th class="text-left">
                  Descripción
                </th>
                <th class="text-center">
                  Operaciones
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(ticketState, i) in getActiveTicketStates" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ ticketState.name }}</td>
                <td>{{ ticketState.description }}</td>
                <td class="text-right">
                  <div v-if="!ticketState.default">
                    <v-btn text small color="primary" @click="openEdit(ticketState.id)">Editar </v-btn>
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small icon class="my-auto mr-5" v-bind="attrs" v-on="on">
                          <v-icon x-small>fas fa-ellipsis-v</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="openDelete(ticketState.id)">
                          <v-list-item-title>Eliminar</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <!-- Modals -->
    <v-dialog v-model="dialog_enable" persistent max-width="400">
      <v-card class="py-3">
        <v-card-text>
          <v-form ref="formState">
            <v-row>
              <v-col cols="12">
                <h3 class="font-weight-thin">
                  {{ state_selected ? `Editar estado "${state_selected.name}"` : "Nuevo estado" }}
                </h3>
              </v-col>
              <v-col v-if="!state_selected" cols="12">
                <v-text-field v-model="state.name" label="Nombre" :rules="rules.norepeat"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="state.description"
                  label="Descripción"
                  rows="2"
                  :rules="rules.required"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="ma-2">
          <v-spacer></v-spacer>
          <v-btn rounded text @click="dialog_enable = false">Cancelar</v-btn>
          <v-btn v-if="state_selected" :loading="loading" color="primary" rounded @click="edit">
            Editar
          </v-btn>
          <v-btn v-else :loading="loading" color="primary" rounded @click="save">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="state_selected" v-model="dialog_delete" persistent max-width="400">
      <v-card class="py-3">
        <v-card-text>
          <v-form ref="formDeleteState">
            <v-row>
              <v-col cols="12">
                <h3 class="font-weight-thin">
                  ¿Está seguro que
                  <span class="font-weight-bold">desea eliminar el estado con {{ state_selected.name }}</span
                  >?.
                </h3>
                <h3 class="font-weight-thin py-3">
                  Para completar está acción debe seleccionar el nuevo estado que reemplazará al estado que va a
                  eliminar
                </h3>
                <v-select
                  v-model="state_to_replace"
                  label="Reemplazar por"
                  :items="tickets_states"
                  item-text="name"
                  item-value="id"
                  :rules="rules.required"
                ></v-select>
              </v-col>
              <v-col v-if="error_form_delete" cols="12">
                <v-alert dense text type="error">
                  {{ error_form_delete }}
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="mr-2">
          <v-spacer></v-spacer>
          <v-btn rounded text @click="dialog_delete = false">Cancelar</v-btn>
          <v-btn :loading="loading" color="red" rounded dark @click="remove(state_selected)">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TicketsStates",
  data() {
    return {
      toggle: false,
      state: {
        id: "",
        name: "",
        description: "",
        active: true,
      },
      state_selected: null,
      state_to_replace: null,
      tickets_states: null,
      error_form_delete: null,
      dialog_enable: false,
      dialog_delete: false,
      loading: false,
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        norepeat: [
          (v) => !!v || "Campo requerido",
          // eslint-disable-next-line
          (v) => !this.getActiveTicketStates.find((t) => t.id == this.state.id) || "El estado ya existe",
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["getActiveTicketStates"]),
  },
  watch: {
    "state.name"(value) {
      this.state.id = this.convertToSlug(value);
    },
  },
  methods: {
    ...mapActions(["createTicketState", "updateTicketState", "removeTicketState"]),
    openEdit(state_id) {
      this.state_selected = this.getActiveTicketStates.find((s) => s.id === state_id);
      this.state = this.state_selected;
      this.dialog_enable = true;
    },
    openDelete(state_id) {
      this.state_selected = this.getActiveTicketStates.find((s) => s.id === state_id);
      this.tickets_states = [...this.getActiveTicketStates.filter((s) => s.id !== this.state_selected.id)];
      this.state_to_replace = null;
      this.error_form_delete = null;
      this.dialog_delete = true;
    },
    async edit() {
      try {
        this.loading = true;

        if (this.$refs.formState.validate()) {
          await this.updateTicketState({ state: this.state });

          this.resetFormState();
          this.dialog_enable = false;
        }
      } catch (error) {
        console.error("ticketState/edit", error);
      } finally {
        this.loading = false;
      }
    },
    async save() {
      try {
        this.loading = true;

        if (this.$refs.formState.validate()) {
          await this.createTicketState({ state: this.state });

          this.resetFormState();
          this.dialog_enable = false;
        }
      } catch (error) {
        console.error("ticketState/save", error);
      } finally {
        this.loading = false;
      }
    },
    async remove() {
      try {
        this.loading = true;

        if (this.$refs.formDeleteState.validate()) {
          await this.removeTicketState({
            state_to_delete: this.state_selected.id,
            state_to_replace: this.state_to_replace,
          });

          this.dialog_delete = false;
          this.state_selected = null;
        }
      } catch (error) {
        console.error("ticketState/remove", error);
        this.error_form_delete = error.message;
      } finally {
        this.loading = false;
      }
    },
    resetFormState() {
      this.state_selected = null;
      this.state = {
        id: "",
        name: "",
        description: "",
        active: true,
      };
      if (this.$refs.formState) this.$refs.formState.resetValidation();
    },
    convertToSlug(text) {
      text = text.replace(/^\s+|\s+$/g, "");
      text = text.toLowerCase();

      var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
      var to = "aaaaaeeeeeiiiiooooouuuunc------";
      for (var i = 0, l = from.length; i < l; i++) {
        text = text.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      text = text
        .replace(/[^a-z0-9 -]/g, "")
        .replace(/\s+/g, "-")
        .replace(/-+/g, "-");

      return text;
    },
  },
};
</script>
