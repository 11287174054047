var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "d-flex align-center pt-4 pt-sm-10" },
        [
          _c("v-col", { attrs: { cols: "12", sm: "6", md: "6" } }, [
            _c(
              "p",
              {
                staticClass:
                  "text-h4 primary--text mb-0 font-weight-black hidden-sm-and-down"
              },
              [_vm._v("Oportunidades")]
            )
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-wrap justify-md-end justify-center",
              attrs: { cols: "12", sm: "6", md: "6" }
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "mr-auto hidden-md-and-up",
                                attrs: { icon: "", large: "" },
                                on: {
                                  click: function($event) {
                                    _vm.showFilters = !_vm.showFilters
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { size: "16" } }, [
                                _vm._v("fas fa-filter")
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.showFilters ? "Ocultar" : "Mostrar") +
                        " filtros"
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pr-4 md-d-flex hidden-md-and-down" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on1 = ref.on1
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "pa-2",
                                    attrs: {
                                      rounded: "",
                                      large: "",
                                      icon: "",
                                      to: "/opportunities",
                                      "exact-active-class": "v-btn--text",
                                      exact: ""
                                    }
                                  },
                                  on1
                                ),
                                [
                                  _c("v-icon", { attrs: { size: "16" } }, [
                                    _vm._v("fas fa-list")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_vm._v(" "), _c("span", [_vm._v("Vista en tabla")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "pa-2",
                                    attrs: {
                                      rounded: "",
                                      large: "",
                                      icon: "",
                                      to: "/opportunities/dashboard",
                                      "exact-active-class": "v-btn--text",
                                      exact: ""
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { size: "16" } }, [
                                    _vm._v("fas fa-th")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_vm._v(" "), _c("span", [_vm._v("Vista por estados")])]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4 my-auto",
                      attrs: {
                        loading: _vm.loadingExport,
                        outlined: "",
                        color: "primary",
                        dark: "",
                        normal: ""
                      },
                      on: { click: _vm.exportData }
                    },
                    [
                      _vm._v("\n          Descargar\n          "),
                      _c("v-icon", { attrs: { right: "", size: "16" } }, [
                        _vm._v("fa-file-download")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "", bottom: "", left: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g({ attrs: { outlined: "", icon: "" } }, on),
                            [
                              _c("v-icon", { attrs: { size: "16" } }, [
                                _vm._v("fa fa-ellipsis-v")
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        { attrs: { to: "/opportunities/importer" } },
                        [
                          _c("v-list-item-title", [
                            _vm._v("Importar oportunidades")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        { attrs: { to: "/opportunities/importer-activities" } },
                        [
                          _c("v-list-item-title", [
                            _vm._v("Importar actividades")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        { attrs: { to: "/opportunities/report-activities" } },
                        [
                          _c("v-list-item-title", [
                            _vm._v("Reporte de actividades")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "d-flex align-center py-2 py-sm-4" },
        [
          _vm._l(_vm.customFilters, function(f, i) {
            return [
              _vm.showFilters
                ? _c(
                    "v-col",
                    {
                      key: i,
                      staticClass: "py-0 mb-1 mb-sm-0",
                      attrs: {
                        cols: f.flex_xs || "12",
                        sm: f.flex_md || "6",
                        md: f.flex_md || "3"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "py-2 px-4 elevation-3 rounded-lg" },
                        [
                          _c("v-select", {
                            staticClass: "px-1",
                            attrs: {
                              value: f.value,
                              items: f.items,
                              label: f.name,
                              readonly:
                                f.readonly_advisor &&
                                _vm.getAgent.role === _vm.$roles.ADVISOR,
                              "item-text": f.show_attr || "name",
                              "item-value": "id"
                            },
                            on: {
                              change: function($event) {
                                return _vm.applyFilters(f.id, $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c(
                "v-card",
                { staticClass: "px-4 elevation-3 rounded-lg py-2" },
                [
                  _c("v-text-field", {
                    staticClass: "d-flex align-center imput-icon",
                    attrs: {
                      label: "Buscar",
                      dense: "",
                      solo: "",
                      "single-line": "",
                      "hide-details": "",
                      flat: "",
                      "append-icon": "fa fa-search"
                    },
                    on: { input: _vm.applySearch }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }