import * as MutationsType from "./mutationsType";
import { tagsCollection } from "@/config/firebase";

export default {
  async getAllTags({ commit }) {
    let tags = await tagsCollection.get();
    commit(MutationsType.SET_TAGS, []);
    if (!tags.empty) {
      tags.forEach((tag) => {
        commit(MutationsType.ADD_TAG, { id: tag.id, ...tag.data() });
      });
    }
  },
  async createTag({ commit }, payload) {
    let tagRef = tagsCollection.doc();
    let tag = Object.assign({}, payload.tag);
    tag.id = tagRef.id;
    await tagRef.set(tag);
    commit(MutationsType.ADD_TAG, tag);
    return tag.id;
  },
  async updateTag({ commit }, payload) {
    await tagsCollection.doc(payload.tag.id).update(payload.tag);
    if (payload.tag.active) commit(MutationsType.UPDATE_TAG, payload.tag);
    return true;
  },
};
