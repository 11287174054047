import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_SALE](state, payload) {
    state.sale = payload;
  },
  [MutationsType.SET_SALES](state, payload) {
    state.sales = payload;
  },
  [MutationsType.ADD_SALE](state, payload) {
    state.sales.push(payload);
  },
};
