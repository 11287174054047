import requestAxios from "@/helpers/request_axios";
import * as MutationsType from "./mutationsType";
import { integrationsCollection } from "@/config/firebase";

export default {
  async getIntegrationById({ commit }, payload) {
    let doc = await integrationsCollection.doc(payload.id).get();
    let config = doc.exists ? { id: doc.id, ...doc.data() } : null;

    commit(MutationsType.SET_INTEGRATION_CONFIG, config);
  },
  // eslint-disable-next-line
  async verifyIntegrationZoomWebinars({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/integration/zoomwebinars/verify-account",
        method: "POST",
        data: payload,
      });
      return response.data;
    } catch (error) {
      console.error("integration/verifyIntegrationZoomWebinars: ", error);
      return error.response.data;
    }
  },
  // eslint-disable-next-line
  async registerUsersToZoomWebinar({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/integration/zoomwebinars/register-attendees",
        method: "POST",
        data: payload,
      });
      return response.data;
    } catch (error) {
      console.error("integration/registerUsersToZoomWebinar: ", error);
      return error.response.data;
    }
  },
};
