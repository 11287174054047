<template>
  <v-app id="agents">
    <div v-if="getAgent && getAllAgentsArray && getAgentRoles && getProjects && getTeams">
      <v-row class="d-flex align-center pt-4 pt-sm-10">
        <v-col cols="12" sm="6" md="9">
          <p class="text-h4 primary--text mb-0 font-weight-black hidden-sm-and-down">Trabajadores</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-card class="py-2 px-4 elevation-3 rounded-lg">
            <v-select
              v-model="team"
              value="todos"
              :items="getTeamsToSelect"
              label="Equipo"
              item-text="name"
              item-value="id"
              class="px-1"
            ></v-select>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card class="py-2 px-4 elevation-3 rounded-lg">
            <v-select
              v-model="project"
              value="todos"
              :items="getProjectsToSelect"
              label="Proyecto"
              item-text="id"
              item-value="id"
              class="px-1"
            ></v-select>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card class="py-2 px-4 elevation-3 rounded-lg">
            <v-select
              v-model="state"
              value="todos"
              :items="getStatesToSelect"
              label="Estado"
              item-text="name"
              item-value="id"
              class="px-1"
            ></v-select>
          </v-card>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="3">
          <v-card class="px-4 elevation-3 rounded-lg py-2">
            <v-text-field
              v-model="search"
              label="Buscar"
              dense
              solo
              single-line
              hide-details
              flat
              class="d-flex align-center imput-icon"
              append-icon="fa fa-search"
            ></v-text-field>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="getAgent.role === $roles.SUPER_ADMIN" class="px-4 hidden-md-and-up">
          <v-btn to="/agents/register" color="primary" elevation-12 block>
            <span> <v-icon size="16" class="mr-2">fa-plus</v-icon> Agregar </span>
          </v-btn>
        </v-col>
      </v-row>
      <v-card v-if="agent_projects" class="px-4 rounded-lg py-1">
        <v-col cols="12" class="pa-4">
          <v-data-table
            :headers="headers"
            :items="getAgentsTableData"
            :search="search"
            :items-per-page="30"
            :mobile-breakpoint="0"
            sort-by
            dense
          >
            <template v-slot:item.role="{ item }">
              <span>{{ getAgentRoles[item.role] ? getAgentRoles[item.role].name : "Sin asignar" }}</span>
            </template>
            <template v-slot:item.team="{ item }">
              <span>{{ item.team_id ? getTeams.find((t) => t.id === item.team_id).name : "" }}</span>
            </template>
            <template v-slot:item.projects="{ item }">
              <span>{{ agent_projects[item.id] ? agent_projects[item.id].toString() : "" }}</span>
            </template>
            <template v-slot:item.active="{ item }">
              <span>
                {{ item.active ? "activo" : "inactivo" }}
              </span>
            </template>
            <template v-slot:item.operations="{ item }">
              <!-- <v-btn text icon :to="`/agent/${item.id}`">
                <v-icon color="primary" dark small>fas fa-external-link-alt</v-icon>
              </v-btn> -->
              <v-btn text icon @click.stop="showAgentDetail({ agentId: item.id })">
                <v-icon color="primary" dark small>fas fa-user-edit</v-icon>
              </v-btn>
            </template>
            <v-alert v-slot:no-results :value="true" color="error" icon="warning"
              >Your search for "{{ search }}" found no results.</v-alert
            >
          </v-data-table>
        </v-col>
      </v-card>
      <v-btn
        v-if="getAgent.role === $roles.SUPER_ADMIN"
        to="/agents/register"
        fixed
        bottom
        right
        x-large
        color="primary"
        elevation-12
        rounded
        class="hidden-sm-and-down"
      >
        <span> <v-icon size="16" class="mr-2">fa-plus</v-icon> Agregar </span>
      </v-btn>
    </div>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import config from "Config/config";

export default {
  name: "Agents",
  data() {
    return {
      search: "",
      project: "todos",
      team: "todos",
      state: "todos",
      agent_projects: null,
      headers: config.agentTable,
      pagination: {
        rowsPerPage: 20,
      },
    };
  },
  computed: {
    ...mapGetters(["getAgent", "getAllAgentsArray", "getAgentRoles", "getProjects", "getTeams"]),
    getProjectsToSelect() {
      return [{ id: "todos" }, ...this.getProjects];
    },
    getTeamsToSelect() {
      return [{ id: "todos", name: "todos" }, ...this.getTeams];
    },
    getStatesToSelect() {
      return [
        { id: "todos", name: "todos" },
        { id: true, name: "activo" },
        { id: false, name: "inactivo" },
      ];
    },
    getAgentsTableData() {
      let data = this.getAllAgentsArray;
      if (this.project !== "todos") {
        data = data.filter((d) => this.agent_projects[d.id].includes(this.project));
      }
      if (this.team !== "todos") {
        data = data.filter((d) => d.team_id === this.team);
      }
      if (this.state !== "todos") {
        data = data.filter((d) => d.active === this.state);
      }
      return data;
    },
  },
  watch: {
    async getAllAgentsArray(value) {
      if (value) await this.getAllProjects();
    },
    async getProjects(value) {
      if (value) {
        this.agent_projects = {};

        for (let index = 0; index < this.getAllAgentsArray.length; index++) {
          const agent = this.getAllAgentsArray[index];
          this.agent_projects[agent.id] = value
            .filter((p) => {
              return p.agents.includes(agent.id);
            })
            .map((a) => a.id);
        }
      }
    },
  },
  async created() {
    await this.getAllTeams();
    await this.getAllAgents();
  },
  methods: {
    ...mapActions(["getAllAgents", "showAgentDetail", "getAllProjects", "getAllTeams"]),
  },
};
</script>
<style>
.max-w180 {
  max-width: 180px;
}
.v-avatar {
  cursor: pointer;
}
.text-decoration-none {
  text-decoration: none;
}
th[aria-label="Email: Not sorted."],
.hidden {
  display: none;
}
.v-btn {
  text-transform: none;
}
</style>
