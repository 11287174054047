import * as MutationsType from "./mutationsType";
import { projectsCollection } from "@/config/firebase";

export default {
  async getAllProjects({ commit }) {
    commit(MutationsType.SET_PROJECTS, []);

    let snap = await projectsCollection.get();
    if (!snap.empty) {
      let projects = [];

      snap.forEach((doc) => {
        projects.push({ id: doc.id, ...doc.data() });
      });

      commit(MutationsType.SET_PROJECTS, projects);
    }
  },
};
