var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "justify-end mr-0 pb-1" },
        [
          _c(
            "v-btn",
            {
              attrs: { rounded: "", color: "primary" },
              on: {
                click: function($event) {
                  _vm.resetFormTeam()
                  _vm.dialog_enable = true
                }
              }
            },
            [
              _c("v-icon", { attrs: { size: "16" } }, [_vm._v("fa-plus")]),
              _vm._v(" "),
              _c("span", { staticClass: "hidden-sm-and-down ml-2" }, [
                _vm._v("Crear equipo")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.getTeams
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pt-0", attrs: { cols: "12" } },
                [
                  _vm.getTeams.length > 0
                    ? _c("v-simple-table", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v(
                                          "\n                #\n              "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v(
                                          "\n                Nombre\n              "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v(
                                          "\n                Descripción\n              "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v(
                                          "\n                Operaciones\n              "
                                        )
                                      ])
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.getTeams, function(t, i) {
                                      return _c("tr", { key: i }, [
                                        _c("td", [_vm._v(_vm._s(i + 1))]),
                                        _vm._v(" "),
                                        _c("td", [_vm._v(_vm._s(t.name))]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(_vm._s(t.description))
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "text-center" },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  text: "",
                                                  small: "",
                                                  color: "primary"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openEdit(t.id)
                                                  }
                                                }
                                              },
                                              [_vm._v("Editar ")]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2243690007
                        )
                      })
                    : _c("p", { staticClass: "text-center py-2" }, [
                        _vm._v("Aún no hay equipos")
                      ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.dialog_enable,
            callback: function($$v) {
              _vm.dialog_enable = $$v
            },
            expression: "dialog_enable"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "py-3" },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    { ref: "formTeam" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("h3", { staticClass: "font-weight-thin" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.team_selected
                                      ? 'Editar equipo "' +
                                          _vm.team_selected.name +
                                          '"'
                                      : "Nuevo equipo"
                                  ) +
                                  "\n              "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pt-0", attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Nombre",
                                  placeholder: "Nombre",
                                  rules: _vm.rules.norepeat
                                },
                                model: {
                                  value: _vm.team.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.team, "name", $$v)
                                  },
                                  expression: "team.name"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c("v-textarea", {
                                staticClass: "expand",
                                attrs: {
                                  label: "Descripción",
                                  placeholder: "Descripción",
                                  rows: "1"
                                },
                                model: {
                                  value: _vm.team.description,
                                  callback: function($$v) {
                                    _vm.$set(_vm.team, "description", $$v)
                                  },
                                  expression: "team.description"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.team_selected
                            ? _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex pt-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("v-select", {
                                        staticClass: "pr-5",
                                        attrs: {
                                          items: _vm.agentsToTeam,
                                          "menu-props": { maxHeight: "400" },
                                          "item-value": "uid",
                                          "item-text": "full_name",
                                          label: "Agentes",
                                          placeholder: "Seleccione y agregue",
                                          "return-object": ""
                                        },
                                        model: {
                                          value: _vm.agent_to_add,
                                          callback: function($$v) {
                                            _vm.agent_to_add = $$v
                                          },
                                          expression: "agent_to_add"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "my-auto",
                                          attrs: {
                                            color: "secondary",
                                            rounded: "",
                                            outlined: ""
                                          },
                                          on: { click: _vm.addAgentToTeam }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Agregar\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _vm.team.agents.length > 0
                                        ? _c(
                                            "h4",
                                            {
                                              staticClass: "primary--text py-3"
                                            },
                                            [_vm._v("Integrantes del equipo")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm._l(_vm.team.agents, function(
                                        agent,
                                        i
                                      ) {
                                        return [
                                          _c(
                                            "v-row",
                                            { key: i, staticClass: "d-flex" },
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "hidden" },
                                                [
                                                  _c("h4", [
                                                    _vm._v(_vm._s(agent.uid))
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0 my-auto",
                                                  attrs: { cols: "12", md: "7" }
                                                },
                                                [
                                                  _c(
                                                    "h4",
                                                    { staticClass: "body-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(agent.full_name)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "10", md: "3" }
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: [
                                                        {
                                                          id:
                                                            _vm.$roles.ADVISOR,
                                                          name: "Asesor"
                                                        },
                                                        {
                                                          id:
                                                            _vm.$roles
                                                              .SUPERVISOR,
                                                          name: "Supervisor"
                                                        }
                                                      ],
                                                      "item-value": "id",
                                                      "item-text": "name"
                                                    },
                                                    model: {
                                                      value: agent.role,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          agent,
                                                          "role",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "agent.role"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "py-0 my-auto text-right",
                                                  attrs: { cols: "2" }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { small: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.removeAgentToTeam(
                                                            agent.uid
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("fas fa-times")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm.error_form
                                                ? _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "v-alert",
                                                        {
                                                          attrs: {
                                                            dense: "",
                                                            text: "",
                                                            type: "error"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.error_form
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { staticClass: "ma-2" },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { rounded: "", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog_enable = false
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _vm._v(" "),
                  _vm.team_selected
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.loading,
                            color: "primary",
                            rounded: ""
                          },
                          on: { click: _vm.edit }
                        },
                        [_vm._v("\n          Editar\n        ")]
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.loading,
                            color: "primary",
                            rounded: ""
                          },
                          on: { click: _vm.save }
                        },
                        [_vm._v("\n          Guardar\n        ")]
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }