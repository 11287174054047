import * as MutationsType from "./mutationsType";

const { zonesCollection } = require("@/config/firebase");

export default {
  async getAllZones({ commit }) {
    let zones = await zonesCollection.get();
    if (!zones.empty) {
      commit(MutationsType.SET_ZONES, []);
      zones.forEach((zone) => {
        commit(MutationsType.ADD_ZONE, { id: zone.id, ...zone.data() });
      });
    }
  },
};
