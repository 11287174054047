export default {
  getTicket(state) {
    return state.ticket;
  },
  getTickets(state) {
    return state.tickets;
  },
  getNewTickets(state) {
    return state.newTickets;
  },
  getSelectedTicketId(state) {
    return state.ticketId;
  },
  getShowTicketDetailDrawer(state) {
    return state.showTicketDetailDrawer;
  },
};
