import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";
import config from "Config/config";

// Initialize Firebase
firebase.initializeApp(config.app);

let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(config.fcm_key);
}
firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
});

const db = firebase.firestore();
const auth = firebase.auth();
const contactsCollection = db.collection("leads");
const agentsCollection = db.collection("agents");
const contactsTimelinesCollection = db.collection("timelines");
const eventsCollection = db.collection("events");
const ordersCollection = db.collection("orders");
const zonesCollection = db.collection("zones");
const ticketsCollection = db.collection("tickets");
const ticketsTimelinesCollection = db.collection("tickets_timelines");
const ticketsStatesCollection = db.collection("tickets_states");
const tagsCollection = db.collection("tags");
const devicesCollection = db.collection("devices");
const projectsCollection = db.collection("projects");
const teamsCollection = db.collection("teams");
const webhooksCollection = db.collection("webhooks");
const integrationsCollection = db.collection("integrations");

const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
googleAuthProvider.setCustomParameters({
  prompt: "select_account",
});

export {
  db,
  auth,
  messaging,
  contactsCollection,
  agentsCollection,
  contactsTimelinesCollection,
  ticketsTimelinesCollection,
  eventsCollection,
  ordersCollection,
  zonesCollection,
  ticketsCollection,
  ticketsStatesCollection,
  tagsCollection,
  devicesCollection,
  projectsCollection,
  teamsCollection,
  webhooksCollection,
  integrationsCollection,
  googleAuthProvider,
  serverTimestamp,
};
