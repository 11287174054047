<template>
  <div>
    <v-timeline v-if="getTimeline(ticketId) && getTimeline(ticketId).length > 0" dense>
      <v-slide-x-transition group>
        <v-timeline-item
          v-for="(c, index) in sortTimeLine"
          :key="index"
          fill-dot
          :color="colorTypeComment[c.type]"
          small
        >
          <template v-slot:icon>
            <v-avatar v-if="getAllAgentsMap[c.uid] && getAllAgentsMap[c.uid].photo" size="24">
              <img :src="getAllAgentsMap[c.uid].photo" :alt="getAllAgentsMap[c.uid].name" />
            </v-avatar>
          </template>
          <v-card class="pl-6 py-2">
            <v-row>
              <strong v-if="getAllAgentsMap[c.uid]" class="pr-1" v-text="getAllAgentsMap[c.uid].full_name"></strong>
              <strong v-if="getAllAgentsMap[c.uid]">·</strong>
              <strong v-if="c.created_at" class="ml-1" v-text="$formatTimestamp(c.created_at, 'short')"></strong>
            </v-row>
            <div class="caption pb-2" v-html="c.text_formatted || c.text"></div>
            <v-chip v-if="getAllAgentsMap[c.uid]" :color="colorTypeComment[c.type]" rounded outlined small class="my-1">
              <v-icon left size="12">{{ iconTypeComment[c.type] }}</v-icon>
              <span v-if="c.type == 1">Llamada</span>
              <span v-else-if="c.type == 2">Correo</span>
              <span v-else-if="c.type == 4">
                {{ `${c.event_date} ${c.event_time} ${c.event_time_end ? ` - ${c.event_time_end}` : ""}` }}
              </span>
              <span v-else>Comentario</span>
            </v-chip>
            <div v-if="c.type == 4 && c.uid === getAgent.uid" class="text-right pa-2">
              <BtnCalendar
                :event-id.sync="c.id"
                :date.sync="c.event_date"
                :hour-start.sync="c.event_time"
                :hour-end.sync="c.event_time_end"
                :title.sync="c.event_name"
                :detail.sync="c.detail"
              />
            </div>
          </v-card>
          <!-- <v-divider></v-divider> -->
        </v-timeline-item>
      </v-slide-x-transition>
    </v-timeline>
    <div v-else class="text-center py-4">Aún no hay comentarios</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BtnCalendar from "@/components/buttons/BtnCalendar";

export default {
  components: {
    BtnCalendar,
  },
  props: {
    ticketId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      colorTypeComment: ["pink", "primary", "cyan", "teal", "green"],
      iconTypeComment: ["fas fa-comment", "fas fa-headset", "fas fa-envelope", "fas fa-user", "far fa-calendar-alt"],
    };
  },

  computed: {
    ...mapGetters(["getAgent", "getAllAgentsMap", "getTimeline", "getSelectedTicketId"]),
    sortTimeLine() {
      return this.getTimeline(this.ticketId);
    },
  },

  async beforeMount() {
    if (this.ticketId) await this.getTicketTimeline({ ticket_id: this.ticketId });
  },
  methods: {
    ...mapActions(["getTicketTimeline"]),
  },
};
</script>
