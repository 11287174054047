var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "agents" } },
    [
      _vm.getAgent &&
      _vm.getAllAgentsArray &&
      _vm.getAgentRoles &&
      _vm.getProjects &&
      _vm.getTeams
        ? _c(
            "div",
            [
              _c(
                "v-row",
                { staticClass: "d-flex align-center pt-4 pt-sm-10" },
                [
                  _c("v-col", { attrs: { cols: "12", sm: "6", md: "9" } }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-h4 primary--text mb-0 font-weight-black hidden-sm-and-down"
                      },
                      [_vm._v("Trabajadores")]
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "py-2 px-4 elevation-3 rounded-lg" },
                        [
                          _c("v-select", {
                            staticClass: "px-1",
                            attrs: {
                              value: "todos",
                              items: _vm.getTeamsToSelect,
                              label: "Equipo",
                              "item-text": "name",
                              "item-value": "id"
                            },
                            model: {
                              value: _vm.team,
                              callback: function($$v) {
                                _vm.team = $$v
                              },
                              expression: "team"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "py-2 px-4 elevation-3 rounded-lg" },
                        [
                          _c("v-select", {
                            staticClass: "px-1",
                            attrs: {
                              value: "todos",
                              items: _vm.getProjectsToSelect,
                              label: "Proyecto",
                              "item-text": "id",
                              "item-value": "id"
                            },
                            model: {
                              value: _vm.project,
                              callback: function($$v) {
                                _vm.project = $$v
                              },
                              expression: "project"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "py-2 px-4 elevation-3 rounded-lg" },
                        [
                          _c("v-select", {
                            staticClass: "px-1",
                            attrs: {
                              value: "todos",
                              items: _vm.getStatesToSelect,
                              label: "Estado",
                              "item-text": "name",
                              "item-value": "id"
                            },
                            model: {
                              value: _vm.state,
                              callback: function($$v) {
                                _vm.state = $$v
                              },
                              expression: "state"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "px-4 elevation-3 rounded-lg py-2" },
                        [
                          _c("v-text-field", {
                            staticClass: "d-flex align-center imput-icon",
                            attrs: {
                              label: "Buscar",
                              dense: "",
                              solo: "",
                              "single-line": "",
                              "hide-details": "",
                              flat: "",
                              "append-icon": "fa fa-search"
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _vm.getAgent.role === _vm.$roles.SUPER_ADMIN
                    ? _c(
                        "v-col",
                        { staticClass: "px-4 hidden-md-and-up" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                to: "/agents/register",
                                color: "primary",
                                "elevation-12": "",
                                block: ""
                              }
                            },
                            [
                              _c(
                                "span",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { size: "16" }
                                    },
                                    [_vm._v("fa-plus")]
                                  ),
                                  _vm._v(" Agregar ")
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.agent_projects
                ? _c(
                    "v-card",
                    { staticClass: "px-4 rounded-lg py-1" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-4", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-data-table",
                            {
                              attrs: {
                                headers: _vm.headers,
                                items: _vm.getAgentsTableData,
                                search: _vm.search,
                                "items-per-page": 30,
                                "mobile-breakpoint": 0,
                                "sort-by": "",
                                dense: ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item.role",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getAgentRoles[item.role]
                                                ? _vm.getAgentRoles[item.role]
                                                    .name
                                                : "Sin asignar"
                                            )
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.team",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.team_id
                                                ? _vm.getTeams.find(function(
                                                    t
                                                  ) {
                                                    return t.id === item.team_id
                                                  }).name
                                                : ""
                                            )
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.projects",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.agent_projects[item.id]
                                                ? _vm.agent_projects[
                                                    item.id
                                                  ].toString()
                                                : ""
                                            )
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.active",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                item.active
                                                  ? "activo"
                                                  : "inactivo"
                                              ) +
                                              "\n            "
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.operations",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { text: "", icon: "" },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.showAgentDetail({
                                                  agentId: item.id
                                                })
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  dark: "",
                                                  small: ""
                                                }
                                              },
                                              [_vm._v("fas fa-user-edit")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                996233632
                              )
                            },
                            [
                              _vm._v(" "),
                              _vm._v(" "),
                              _vm._v(" "),
                              _vm._v(" "),
                              _vm._v(" "),
                              _c("v-alert", {
                                attrs: {
                                  value: true,
                                  color: "error",
                                  icon: "warning"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "no-results",
                                      fn: function() {
                                        return [
                                          _vm._v(
                                            'Your search for "' +
                                              _vm._s(_vm.search) +
                                              '" found no results.'
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2478838959
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.getAgent.role === _vm.$roles.SUPER_ADMIN
                ? _c(
                    "v-btn",
                    {
                      staticClass: "hidden-sm-and-down",
                      attrs: {
                        to: "/agents/register",
                        fixed: "",
                        bottom: "",
                        right: "",
                        "x-large": "",
                        color: "primary",
                        "elevation-12": "",
                        rounded: ""
                      }
                    },
                    [
                      _c(
                        "span",
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-2", attrs: { size: "16" } },
                            [_vm._v("fa-plus")]
                          ),
                          _vm._v(" Agregar ")
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }