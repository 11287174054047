var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "agent_form" } },
    [
      _vm.agent
        ? _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "12" } },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { id: "form-agent", "lazy-validation": "" }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: _vm.sm || 6 } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Nombres",
                              placeholder: " ",
                              rules: _vm.rules.required,
                              readonly: _vm.isReadOnly
                            },
                            model: {
                              value: _vm.agent.name,
                              callback: function($$v) {
                                _vm.$set(_vm.agent, "name", $$v)
                              },
                              expression: "agent.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: _vm.sm || 6 } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Apellidos",
                              placeholder: " ",
                              rules: _vm.rules.required,
                              readonly: _vm.isReadOnly
                            },
                            model: {
                              value: _vm.agent.last_name,
                              callback: function($$v) {
                                _vm.$set(_vm.agent, "last_name", $$v)
                              },
                              expression: "agent.last_name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: _vm.sm || 6 } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Correo electrónico",
                              placeholder: " ",
                              rules: _vm.rules.required,
                              readonly: _vm.isReadOnly
                            },
                            model: {
                              value: _vm.agent.email,
                              callback: function($$v) {
                                _vm.$set(_vm.agent, "email", $$v)
                              },
                              expression: "agent.email"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: _vm.sm || 6 } },
                        [
                          _c("v-select", {
                            attrs: {
                              placeholder: "Seleccione",
                              items: _vm.getAgentRolesToArray,
                              rules: _vm.rules.required,
                              readonly: _vm.isReadOnly,
                              "item-text": "name",
                              "item-value": "id",
                              label: "Rol"
                            },
                            model: {
                              value: _vm.agent.role,
                              callback: function($$v) {
                                _vm.$set(_vm.agent, "role", $$v)
                              },
                              expression: "agent.role"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      [_vm.$roles.ADVISOR, _vm.$roles.SUPERVISOR].includes(
                        _vm.agent.role
                      )
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.getTeams,
                                  "item-value": "id",
                                  "item-text": "name",
                                  "menu-props": { maxHeight: "400" },
                                  readonly: _vm.isReadOnly,
                                  rules: _vm.rules.required,
                                  label: "Equipo",
                                  placeholder: " ",
                                  hint:
                                    "Para crear un nuevo equipo ve a configuraciones",
                                  "persistent-hint": ""
                                },
                                model: {
                                  value: _vm.agent.team_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.agent, "team_id", $$v)
                                  },
                                  expression: "agent.team_id"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.getProjects,
                              "item-value": "id",
                              "item-text": "id",
                              "menu-props": { maxHeight: "400" },
                              readonly: _vm.isReadOnly,
                              label: "Proyectos",
                              placeholder: " ",
                              multiple: "",
                              hint:
                                "Proyectos donde va a ser asignado automaticamente",
                              "persistent-hint": ""
                            },
                            model: {
                              value: _vm.agent.projects,
                              callback: function($$v) {
                                _vm.$set(_vm.agent, "projects", $$v)
                              },
                              expression: "agent.projects"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.errorForm
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-alert",
                                { attrs: { text: "", type: "error" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.errorForm) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.action === "create"
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.loading
                                  },
                                  on: { click: _vm.handleForm }
                                },
                                [_vm._v("Guardar trabajador")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.action === "update"
                        ? _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    rounded: "",
                                    color: "accent",
                                    loading: _vm.loading
                                  },
                                  on: { click: _vm.handleForm }
                                },
                                [_vm._v("Guardar")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { small: "", rounded: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.setAgent()
                                      _vm.$emit("update:action", "read")
                                    }
                                  }
                                },
                                [_vm._v("Cancelar")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }