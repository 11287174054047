<template>
  <v-container class="pt-0 pb-0">
    <v-row class="justify-end pr-3">
      <v-dialog v-model="dialog" persistent max-width="400">
        <template v-slot:activator="{ on }">
          <v-btn x-small color="primary" outlined dark v-on="on" @click="dialog = true">Administrar etiquetas</v-btn>
        </template>
        <v-card>
          <v-card-title class="title px-2">
            <h5 class="pl-2">ETIQUETAS</h5>
            <v-spacer></v-spacer>
            <v-btn v-if="action != 'create'" small color="primary" class="mr-2" @click="create">Nueva etiqueta</v-btn>
            <v-btn icon @click="dialog = false"><v-icon small>fa fa-times</v-icon></v-btn>
          </v-card-title>
          <v-card-text class="pa-5">
            <v-row>
              <v-col v-if="openForm" cols="12" class="pb-5">
                <v-form ref="formTag" lazy-validation>
                  <v-col cols="12" class="py-0">
                    <h3>{{ action == "create" ? "Nueva etiqueta" : "Editar etiqueta" }}</h3>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="tag.name" label="Nombre" placeholder=" " :rules="rules.name"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-color-picker
                      v-model="tag.color"
                      :rules="rules.required"
                      hide-inputs
                      dot-size="12"
                      type="hex"
                      canvas-height="100"
                    ></v-color-picker>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-chip :color="tag.color">{{ tag.name || "etiqueta" }}</v-chip>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <v-btn v-if="action == 'create'" color="primary" :loading="loading" @click="saveTag">Guardar</v-btn>
                    <v-btn v-else color="primary" :loading="loading" @click="saveTag">Editar</v-btn>
                    <v-btn color="gray darken-1" text @click="resetTagForm">Cancelar</v-btn>
                  </v-col>
                </v-form>
              </v-col>
            </v-row>
            <v-row v-if="getActiveTags.length > 0">
              <template v-for="(t, i) in getActiveTags">
                <v-col :key="i" cols="12" class="d-flex pa-0">
                  <v-col cols="9" class="py-2"
                    ><v-chip small :color="t.color">{{ t.name }}</v-chip></v-col
                  >
                  <v-col cols="3" class="py-2 text-right">
                    <v-icon v-if="auxTag.id != t.id" x-small class="pr-4" @click="update(t)">fas fa-pencil-alt</v-icon>
                    <v-icon x-small @click="deleteTag(t)">fa fa-trash</v-icon>
                  </v-col>
                </v-col>
              </template>
            </v-row>
            <h4 v-else class="text-center pa-2">No hay etiquetas</h4>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import getRandomColor from "@/helpers/get_randomcolor";

export default {
  name: "TagModal",
  data() {
    return {
      dialog: false,
      tag: {
        id: "",
        name: "",
        color: getRandomColor(),
        active: true,
      },
      auxTag: {
        id: "",
        name: "",
      },
      openForm: false,
      action: "",
      loading: false,
      isRepeated: false,
      loadSave: false,
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        name: [(v) => !!v || "Campo requerido", (v) => (v && !this.isRepeated) || "La etiqueta ya existe"],
      },
    };
  },
  computed: {
    ...mapGetters(["getTags", "getActiveTags"]),
  },
  watch: {
    "tag.name"(value) {
      this.tag.name = value.trim().toLowerCase();
    },
  },
  methods: {
    ...mapActions(["getAllTags", "createTag", "updateTag"]),
    async saveTag() {
      try {
        this.loading = true;
        this.isRepeated = this.getTags.find((item) => {
          return item.name === this.tag.name && item.active && item.name !== this.auxTag.name;
        });

        if (this.$refs.formTag.validate()) {
          if (this.action === "create") await this.createTag({ tag: this.tag });
          else await this.updateTag({ tag: this.tag });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.resetTagForm();
      }
    },
    async deleteTag(tag) {
      tag.active = false;
      await this.updateTag({ tag: tag });
    },
    create() {
      this.resetTagForm();
      this.action = "create";
      this.openForm = true;
    },
    update(tag) {
      this.auxTag = Object.assign({}, tag);
      this.tag = this.auxTag;
      this.action = "update";
      this.openForm = true;
    },
    resetTagForm() {
      this.action = "";
      this.tag.id = "";
      this.tag.name = "";
      this.tag.color = getRandomColor();
      if (this.$refs.formTag) this.$refs.formTag.resetValidation();
      this.openForm = false;
      this.auxTag = { id: "" };
    },
  },
};
</script>
