var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    {
      style: { background: _vm.$vuetify.theme.themes[_vm.theme].background },
      attrs: { id: "login" }
    },
    [
      _c(
        "v-card",
        { staticClass: "fill-height rounded-lg pa-0 mb-8" },
        [
          _c(
            "v-container",
            { staticClass: "fill-height pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { staticClass: "fill-height" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "fill-height",
                      attrs: { cols: "12", sm: "8", md: "6" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "fill-height d-flex flex-column justify-center px-8"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-center text-center items-center"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-9 col-md-6 pt-3 pb-5" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.getLogoImage,
                                      width: "100%"
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "h3",
                            {
                              staticClass:
                                "font-weight-thin title pt-2 pb-5 text-center"
                            },
                            [_vm._v("La forma más simple de vender más")]
                          ),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "div",
                              { staticClass: "pa-8 text-center" },
                              [
                                _c(
                                  "v-form",
                                  {
                                    ref: "formSignin",
                                    staticClass: "text-center",
                                    attrs: { "lazy-validation": "" }
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "mb-4",
                                      attrs: {
                                        rules: _vm.rules.email,
                                        label: "Correo electrónico",
                                        placeholder: " "
                                      },
                                      model: {
                                        value: _vm.user.email,
                                        callback: function($$v) {
                                          _vm.$set(_vm.user, "email", $$v)
                                        },
                                        expression: "user.email"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: {
                                        rules: _vm.rules.required,
                                        label: "Contraseña",
                                        placeholder: " ",
                                        type: "password"
                                      },
                                      model: {
                                        value: _vm.user.password,
                                        callback: function($$v) {
                                          _vm.$set(_vm.user, "password", $$v)
                                        },
                                        expression: "user.password"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm.error
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "error--text pb-2",
                                            attrs: { cols: "12" }
                                          },
                                          [_vm._v(_vm._s(_vm.error))]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "font-weight-bold px-4 my-4 w-full",
                                        attrs: {
                                          large: "",
                                          color: "primary",
                                          loading: _vm.loading
                                        },
                                        on: { click: _vm.loginUser }
                                      },
                                      [_vm._v("Iniciar sesion")]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      text: "",
                                      small: "",
                                      large: "",
                                      color: "primary"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.dialogRecovery = true
                                        _vm.resetForm()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Recuperar contraseña\n                "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-dialog",
                            {
                              attrs: { "max-width": "400", persistent: "" },
                              model: {
                                value: _vm.dialogRecovery,
                                callback: function($$v) {
                                  _vm.dialogRecovery = $$v
                                },
                                expression: "dialogRecovery"
                              }
                            },
                            [
                              _c(
                                "v-card",
                                { staticClass: "pa-2" },
                                [
                                  _c("v-col", [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "font-weight-thin title pt-2 pb-5"
                                      },
                                      [_vm._v("Recuperar contraseña")]
                                    ),
                                    _vm._v(" "),
                                    _c("h5", [
                                      _vm._v(
                                        "\n                    Ingrese su correo electrónico y le enviaremos un correo con un link para que cambie su contraseña\n                  "
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-form",
                                        {
                                          ref: "formRecovery",
                                          attrs: { "lazy-validation": "" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              rules: _vm.rules.email,
                                              label: "Correo electrónico",
                                              placeholder: " "
                                            },
                                            model: {
                                              value: _vm.email,
                                              callback: function($$v) {
                                                _vm.email = $$v
                                              },
                                              expression: "email"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.errorSend
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "error--text pb-2",
                                          attrs: { cols: "12" }
                                        },
                                        [_vm._v(_vm._s(_vm.errorSend))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { staticClass: "text-center" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            rounded: "",
                                            color: "primary",
                                            loading: _vm.sending
                                          },
                                          on: { click: _vm.send }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    Enviar\n                  "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { rounded: "", text: "" },
                                          on: {
                                            click: function($event) {
                                              _vm.dialogRecovery = false
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    Cancelar\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.successSend
                                    ? _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                dense: "",
                                                text: "",
                                                type: "success"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Se envió con éxito el correo para reestablecer tu contraseña\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0",
                      attrs: { cols: "12", sm: "8", md: "6" }
                    },
                    [
                      _c("div", {
                        staticClass: "fill-height fondo rounded-xl",
                        style: {
                          "background-image":
                            "url(" + _vm.getBAckgroundImage() + ")"
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }