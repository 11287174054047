import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_DEVICES](state, payload) {
    state.devices = payload;
  },
  [MutationsType.ADD_DEVICE](state, payload) {
    state.devices.push(payload);
  },
};
